import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Table, 
  Tag, 
  Button, 
  Modal, 
  Form, 
  Input, 
  DatePicker, 
  Select, 
  Space, 
  Typography, 
  message, 
  Spin, 
  Popconfirm 
} from 'antd';
import { 
  EditOutlined, 
  DeleteOutlined, 
  PlusOutlined,
  CalendarOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import { Switch } from 'antd';
import moment from 'moment';
import { useKeycloak } from '@react-keycloak/web';

const { Text, Title } = Typography;
const { Option } = Select;

const HostExperiencesCompact = () => {
  const [experiences, setExperiences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isExperienceModalVisible, setIsExperienceModalVisible] = useState(false);
  const [isRecurrenceModalVisible, setIsRecurrenceModalVisible] = useState(false);
  const [currentExperience, setCurrentExperience] = useState(null);
  const [form] = Form.useForm();
  const [recurrenceForm] = Form.useForm();
  const { keycloak } = useKeycloak();

  const fetchExperiences = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_SER}experience/hostn`, {
        params: { f: 0, e: 120 },
        headers: { Authorization: `Bearer ${keycloak.token}` },
      });
      setExperiences(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching experiences:', error);
      message.error('Échec du chargement des expériences');
      setLoading(false);
    }
  };

  const handleDeleteExperience = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_SER}experience/${id}`, {
        headers: { Authorization: `Bearer ${keycloak.token}` },
      });
      message.success('Expérience supprimée avec succès');
      fetchExperiences();
    } catch (error) {
      console.error('Error deleting experience:', error);
      message.error('Échec de la suppression de l\'expérience');
    }
  };

  const handleEditExperience = async (values) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_SER}experience/${currentExperience.id}`, values, {
        headers: { Authorization: `Bearer ${keycloak.token}` },
      });
      message.success('Expérience mise à jour avec succès');
      setIsExperienceModalVisible(false);
      fetchExperiences();
    } catch (error) {
      console.error('Error updating experience:', error);
      message.error('Échec de la mise à jour de l\'expérience');
    }
  };

  const handleEditRecurrences = async (values) => {
    try {
      const formattedRecurrences = values.recurenceDates.map((recurrence) => ({
        jour: recurrence.jour,
        frequency: recurrence.frequency || false,
        dateDebut: recurrence.dateDebut
          ? recurrence.dateDebut.toISOString() // If it's a Date object
          : null,
        dateFin: recurrence.dateFin
          ? recurrence.dateFin.toISOString() // If it's a Date object
          : null,
      }));
      
      await axios.put(
        `${process.env.REACT_APP_API_SER}experience/${currentExperience.id}/recurrences`,
        formattedRecurrences,
        {
          headers: { Authorization: `Bearer ${keycloak.token}` },
        }
      );
  
      message.success("Dates de récurrence mises à jour avec succès");
      setIsRecurrenceModalVisible(false);
      fetchExperiences();
    } catch (error) {
      console.error("Error updating recurrences:", error);
      message.error("Échec de la mise à jour des récurrences");
    }
  };
  

  useEffect(() => {
    fetchExperiences();
  }, []);

  const columns = [
    {
      title: 'Titre',
      dataIndex: 'titre',
      key: 'titre',
      render: (titre, record) => (
        <div>
          <Text strong>{titre}</Text>
          <div>
            <Tag color="blue">{record.typeCuisine}</Tag>
          </div>
        </div>
      )
    },
    {
      title: 'Date',
      dataIndex: 'temps',
      key: 'temps',
      render: (temps, record) => (
        <div>
          <CalendarOutlined style={{ marginRight: 8 }} />
          <Text>{moment(temps).format('DD MMM YYYY')}</Text>
          <div>
            <ClockCircleOutlined style={{ marginRight: 8 }} />
            <Text type="secondary">{`${record.heurDebut} - ${record.heurFin}`}</Text>
          </div>
        </div>
      )
    },
    {
      title: 'Participants',
      dataIndex: 'nombreInvites',
      key: 'nombreInvites',
      render: (nombreInvites, record) => (
        <div>
          <Text strong>{`${nombreInvites} / ${record.nombreMaxInvite}`}</Text>
        </div>
      )
    },
    {
      title: 'Prix',
      dataIndex: 'prixParInvite',
      key: 'prixParInvite',
      render: (prixParInvite) => (
        <Tag color="green">{`${prixParInvite} €`}</Tag>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button 
            icon={<EditOutlined />} 
            onClick={() => {
              setCurrentExperience(record);
              form.setFieldsValue({
                titre: record.titre,
                typeCuisine: record.typeCuisine,
                description: record.description,
                nombreInvites: record.nombreInvites,
                prixParInvite: record.prixParInvite,
                localisation: record.localisation,
                temps: [moment(record.temps), moment(`${record.heurDebut}:00`, 'HH:mm:ss'), moment(`${record.heurFin}:00`, 'HH:mm:ss')],
                nombreMaxInvite: record.nombreMaxInvite,
              });
              setIsExperienceModalVisible(true);
            }}
          >
            Modifier
          </Button>
          <Popconfirm
            title="Êtes-vous sûr de vouloir supprimer cette expérience ?"
            onConfirm={() => handleDeleteExperience(record.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button icon={<DeleteOutlined />} danger>
              Supprimer
            </Button>
          </Popconfirm>
          <Button 
            icon={<CalendarOutlined />} 
            onClick={() => {
              setCurrentExperience(record);
              recurrenceForm.setFieldsValue({
                recurenceDates: record.recurenceDates || []
              });
              setIsRecurrenceModalVisible(true);
            }}
          >
            Récurrences
          </Button>
        </Space>
      )
    }
  ];

  return (
    <div style={{ padding: 24 }}>
      <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title level={3}>Mes Expériences Culinaires</Title>
        <Button 
          type="primary" 
          icon={<PlusOutlined />}
          onClick={() => {/* Create experience logic */}}
        >
          Nouvelle Expérience
        </Button>
      </div>

      {loading ? (
        <div style={{ textAlign: 'center', padding: 50 }}>
          <Spin size="large" />
        </div>
      ) : (
        <Table 
          columns={columns} 
          dataSource={experiences}
          rowKey="id"
          locale={{ emptyText: 'Aucune expérience disponible' }}
        />
      )}

      {/* Experience Edit Modal */}
      <Modal
        title="Modifier l'Expérience"
        visible={isExperienceModalVisible}
        onCancel={() => setIsExperienceModalVisible(false)}
        onOk={() => form.submit()}
        okText="Enregistrer"
        cancelText="Annuler"
      >
        <Form form={form} onFinish={handleEditExperience} layout="vertical">
          <Form.Item name="titre" label="Titre" rules={[{ required: true, message: 'Titre requis' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="typeCuisine" label="Type de Cuisine" rules={[{ required: true, message: 'Type de cuisine requis' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Description requise' }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="nombreInvites" label="Nombre d'Invités" rules={[{ required: true, message: 'Nombre d\'invités requis' }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="prixParInvite" label="Prix par Invité" rules={[{ required: true, message: 'Prix requis' }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="localisation" label="Localisation" rules={[{ required: true, message: 'Localisation requise' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="temps" label="Date et Heure" rules={[{ required: true, message: 'Date et heure requises' }]}>
            <DatePicker.RangePicker showTime />
          </Form.Item>
        </Form>
      </Modal>

      {/* Recurrence Edit Modal */}
      <Modal
        title="Modifier les Récurrences"
        visible={isRecurrenceModalVisible}
        onCancel={() => setIsRecurrenceModalVisible(false)}
        onOk={() => recurrenceForm.submit()}
        okText="Enregistrer"
        cancelText="Annuler"
      >
        <Form form={recurrenceForm} onFinish={handleEditRecurrences} layout="vertical">
          <Form.List name="recurenceDates">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'jour']}
                      rules={[{ required: true, message: 'Jour requis' }]}
                    >
                      <Select placeholder="Jour">
                        <Option value="Lundi">Lundi</Option>
                        <Option value="Mardi">Mardi</Option>
                        <Option value="Mercredi">Mercredi</Option>
                        <Option value="Jeudi">Jeudi</Option>
                        <Option value="Vendredi">Vendredi</Option>
                        <Option value="Samedi">Samedi</Option>
                        <Option value="Dimanche">Dimanche</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'dateDebut']}
                      rules={[{ required: true, message: 'Date de début requise' }]}
                    >
                      <DatePicker placeholder="Date de début" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'dateFin']}
                      rules={[{ required: true, message: 'Date de fin requise' }]}
                    >
                      <DatePicker placeholder="Date de fin" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'frequency']}
                      valuePropName="checked"
                    >
                      <Switch checkedChildren="Récurrent" unCheckedChildren="Ponctuel" />
                    </Form.Item>
                    <Button onClick={() => remove(name)}>Supprimer</Button>
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Ajouter une récurrence
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
};

export default HostExperiencesCompact;
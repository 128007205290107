import React, { useState, useEffect } from 'react';
import { 
  Table, Card, Space, Input, Button, Modal, Avatar, 
  Typography, Row, Col, Statistic, Tag, Tooltip, Badge,
  Progress, Divider, Alert, Radio
} from 'antd';
import {
  UserOutlined, PhoneOutlined, MailOutlined, 
  CheckCircleOutlined, StopOutlined, SearchOutlined,
  StarOutlined, EnvironmentOutlined, LineChartOutlined,
  TeamOutlined, RiseOutlined, FallOutlined
} from '@ant-design/icons';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';
import { CalendarOutlined, ShopOutlined, DollarOutlined } from '@ant-design/icons';
import { Area } from '@ant-design/plots';
import { Bar } from '@ant-design/plots';


import axios from 'axios';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const { Title, Text } = Typography;
const { Search } = Input;

const HostsDashboard = () => {
  const [hosts, setHosts] = useState([]);
  const [filteredHosts, setFilteredHosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedHost, setSelectedHost] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [analyticsData, setAnalyticsData] = useState({
    totalHosts: 0,
    activeHosts: 0,
    averageRating: 0,
    cityCounts: {},
    ratingDistribution: {},
    monthlyTrends: []
  });
  const [viewMode, setViewMode] = useState('table');

  useEffect(() => {
    fetchHosts();
  }, []);

  useEffect(() => {
    if (hosts.length > 0) {
      calculateAnalytics();
    }
  }, [hosts]);

  const fetchHosts = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SER}api/host`);
      setHosts(res.data);
      setFilteredHosts(res.data);
    } catch (error) {
      toast.error('Failed to load hosts data');
    } finally {
      setLoading(false);
    }
  };

  const calculateAnalytics = () => {
    const activeHosts = hosts.filter(host => host.isEnabled).length;
    const averageRating = hosts.reduce((acc, host) => acc + (host.rating || 0), 0) / hosts.length;
    
    // Calculate city distribution
    const cityCounts = hosts.reduce((acc, host) => {
      if (host.city) {
        acc[host.city] = (acc[host.city] || 0) + 1;
      }
      return acc;
    }, {});

    // Calculate rating distribution
    const ratingDistribution = hosts.reduce((acc, host) => {
      const rating = Math.floor(host.rating || 0);
      acc[rating] = (acc[rating] || 0) + 1;
      return acc;
    }, {});

    // Generate mock monthly trends (in real app, this would come from backend)
    const monthlyTrends = [
      { month: 'Jan', hosts: hosts.length - 5 },
      { month: 'Feb', hosts: hosts.length - 3 },
      { month: 'Mar', hosts: hosts.length - 2 },
      { month: 'Apr', hosts: hosts.length }
    ];

    setAnalyticsData({
      totalHosts: hosts.length,
      activeHosts,
      averageRating,
      cityCounts,
      ratingDistribution,
      monthlyTrends
    });
  };

  const columns = [
    {
      title: 'Host',
      key: 'host',
      fixed: 'left',
      width: 250,
      render: (text, record) => (
        <Space>
          <Avatar 
            size={40} 
            src={record.avatar ? `${process.env.REACT_APP_API_SER}photos/${record.avatar}` : null}
            icon={!record.avatar && <UserOutlined />}
          />
          <Space direction="vertical" size={0}>
            <Text strong>{`${record.nom} ${record.prenom}`}</Text>
            <Text type="secondary" style={{ fontSize: '12px' }}>{record.email}</Text>
          </Space>
        </Space>
      )
    },
    {
      title: 'Location',
      dataIndex: 'city',
      key: 'city',
      render: city => (
        <Space>
          <EnvironmentOutlined />
          <Text>{city || 'N/A'}</Text>
        </Space>
      )
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      sorter: (a, b) => a.rating - b.rating,
      render: rating => (
        <Space>
          <StarOutlined style={{ color: '#faad14' }} />
          <Text>{rating ? rating.toFixed(1) : 'N/A'}</Text>
        </Space>
      )
    },
    {
      title: 'Status',
      key: 'status',
      render: record => (
        <Tag color={record.isEnabled ? 'success' : 'error'}>
          {record.isEnabled ? 'Active' : 'Disabled'}
        </Tag>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      render: (text, record) => (
        <Space>
          <Button 
            type="primary" 
            onClick={() => {
              setSelectedHost(record);
              setModalVisible(true);
            }}
          >
            View Details
          </Button>
        </Space>
      )
    }
  ];

  const handleSearch = (value) => {
    const filtered = hosts.filter(host => 
      host.nom.toLowerCase().includes(value.toLowerCase()) ||
      host.prenom.toLowerCase().includes(value.toLowerCase()) ||
      host.email.toLowerCase().includes(value.toLowerCase()) ||
      (host.city && host.city.toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredHosts(filtered);
    setSearchText(value);
  };

  const handleStatusChange = async (hostId, newStatus) => {
    try {
      const endpoint = newStatus ? 'enable' : 'disable';
      await axios.post(`${process.env.REACT_APP_API_SER}api/host/${endpoint}/${hostId}`);
      
      setHosts(prevHosts =>
        prevHosts.map(host =>
          host.id === hostId ? { ...host, isEnabled: newStatus } : host
        )
      );
      
      toast.success(`Host ${newStatus ? 'enabled' : 'disabled'} successfully`);
      setModalVisible(false);
    } catch (error) {
      toast.error(`Failed to ${newStatus ? 'enable' : 'disable'} host`);
    }
  };

  const AnalyticsView = () => (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Hosts"
              value={analyticsData.totalHosts}
              prefix={<TeamOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Active Hosts"
              value={analyticsData.activeHosts}
              prefix={<CheckCircleOutlined />}
              suffix={`/ ${analyticsData.totalHosts}`}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Average Rating"
              value={analyticsData.averageRating}
              precision={2}
              prefix={<StarOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Growth Rate"
              value={((analyticsData.totalHosts / (analyticsData.totalHosts - 2)) * 100 - 100).toFixed(1)}
              prefix={<RiseOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
      </Row>

      <Card title="Host Growth Trend">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={analyticsData.monthlyTrends}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <RechartsTooltip />
            <Line type="monotone" dataKey="hosts" stroke="#1890ff" />
          </LineChart>
        </ResponsiveContainer>
      </Card>

      <Row gutter={16}>
        <Col span={12}>
          <Card title="Rating Distribution">
            {Object.entries(analyticsData.ratingDistribution).map(([rating, count]) => (
              <div key={rating} style={{ marginBottom: '10px' }}>
                <Text>{rating} Stars</Text>
                <Progress 
                  percent={(count / analyticsData.totalHosts * 100).toFixed(1)} 
                  strokeColor="#faad14"
                />
              </div>
            ))}
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Top Cities">
            {Object.entries(analyticsData.cityCounts)
              .sort(([,a], [,b]) => b - a)
              .slice(0, 5)
              .map(([city, count]) => (
                <div key={city} style={{ marginBottom: '10px' }}>
                  <Text>{city}</Text>
                  <Progress 
                    percent={(count / analyticsData.totalHosts * 100).toFixed(1)} 
                    strokeColor="#52c41a"
                  />
                </div>
              ))}
          </Card>
        </Col>
      </Row>
    </Space>
  );

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={4}>Hosts Management Dashboard</Title>
          </Col>
          <Col>
            <Space>
              <Radio.Group value={viewMode} onChange={e => setViewMode(e.target.value)}>
                <Radio.Button value="table">Table View</Radio.Button>
                <Radio.Button value="analytics">Analytics</Radio.Button>
              </Radio.Group>
              <Search
                placeholder="Search hosts..."
                allowClear
                enterButton={<SearchOutlined />}
                onSearch={handleSearch}
                style={{ width: 300 }}
              />
            </Space>
          </Col>
        </Row>
      </Card>

      {viewMode === 'table' ? (
        <Card>
          <Table
            columns={columns}
            dataSource={filteredHosts}
            rowKey="id"
            loading={loading}
            scroll={{ x: 1300 }}
            pagination={{
              pageSize: 10,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} hosts`
            }}
          />
        </Card>
      ) : (
        <AnalyticsView />
      )}

     
<Modal
  title="Host Details"
  open={modalVisible}
  onCancel={() => setModalVisible(false)}
  footer={[
    <Button key="close" onClick={() => setModalVisible(false)}>
      Close
    </Button>,
    selectedHost?.isEnabled ? (
      <Button
        key="disable"
        type="primary"
        danger
        onClick={() => handleStatusChange(selectedHost.id, false)}
      >
        Disable Host
      </Button>
    ) : (
      <Button
        key="enable"
        type="primary"
        onClick={() => handleStatusChange(selectedHost.id, true)}
      >
        Enable Host
      </Button>
    )
  ]}
  width={1000}
>
  {selectedHost && (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      {/* Host Basic Information */}
      <Row gutter={16}>
        <Col span={8}>
          <Space direction="vertical" align="center" style={{ width: '100%' }}>
            <Avatar 
              size={120} 
              src={selectedHost.avatar ? `${process.env.REACT_APP_API_SER}photos/${selectedHost.avatar}` : null}
              icon={!selectedHost.avatar && <UserOutlined />}
              style={{ border: '2px solid #1890ff' }}
            />
            <Badge
              status={selectedHost.isEnabled ? 'success' : 'error'}
              text={selectedHost.isEnabled ? 'Active' : 'Disabled'}
            />
          </Space>
        </Col>
        <Col span={16}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Title level={4}>{`${selectedHost.nom} ${selectedHost.prenom}`}</Title>
            <Space>
              <MailOutlined />
              <Text>{selectedHost.email}</Text>
            </Space>
            <Space>
              <PhoneOutlined />
              <Text>{selectedHost.numTele || 'N/A'}</Text>
            </Space>
            <Space>
              <EnvironmentOutlined />
              <Text>{selectedHost.city || 'N/A'}</Text>
            </Space>
            <Space>
              <StarOutlined />
              <Text>{selectedHost.rating ? `${selectedHost.rating} / 5` : 'N/A'}</Text>
            </Space>
            <Space>
              <CalendarOutlined />
              <Text>Joined on: {new Date(selectedHost.createdAt).toLocaleDateString() || 'N/A'}</Text>
            </Space>
          </Space>
        </Col>
      </Row>

      <Divider />

      {/* Host Account Status */}
      {!selectedHost.isEnabled && (
        <Alert
          message="Host Account Disabled"
          description="This host account is currently disabled and cannot accept new bookings."
          type="warning"
          showIcon
        />
      )}

      {/* Host Statistics */}
      <Row gutter={16}>
        <Col span={12}>
          <Card title="Host Performance">
            <Statistic
              title="Total Experiences Hosted"
              value={selectedHost.totalExperiences || 0}
              prefix={<ShopOutlined />}
            />
            <Statistic
              title="Average Guests per Experience"
              value={selectedHost.averageGuests || 0}
              prefix={<UserOutlined />}
              style={{ marginTop: 16 }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Revenue Overview">
            <Statistic
              title="Total Revenue (MAD)"
              value={selectedHost.totalRevenue || 0}
              prefix={<DollarOutlined />}
              precision={2}
            />
            <Statistic
              title="Average Revenue per Experience (MAD)"
              value={selectedHost.averageRevenue || 0}
              prefix={<LineChartOutlined />}
              precision={2}
              style={{ marginTop: 16 }}
            />
          </Card>
        </Col>
      </Row>

      {/* Charts */}
      <Divider />
      <Row gutter={16}>
      <Col span={12}>
  <Card title="Monthly Revenue">
    <Area
      data={selectedHost.revenueData || []}
      xField="month"
      yField="revenue"
      point={{ size: 5, shape: 'diamond' }}
      height={200}
    />
  </Card>
</Col>
<Col span={12}>
  <Card title="Booking Trends">
    <Bar
      data={selectedHost.bookingData || []}
      xField="month"
      yField="bookings"
      barWidthRatio={0.5}
      height={200}
    />
  </Card>
</Col>

      </Row>
    </Space>
  )}
</Modal>



    </Space>
  );
};

export default HostsDashboard;
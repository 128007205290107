import React from "react";
import styled from "styled-components";

// Styled component for the main container
const MenuWrapper = styled.div`
  width: 83%;
  height: 800px;
  padding: 60px;
  margin-bottom: 30px;
  background-image: url("/imgs/designMenu.png");
  background-size: cover;
  background-position: center;
  border: 1px solid #dba976;
  border-radius: 20px;
  position: relative;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  overflow: hidden;
  margin: 2rem auto;
  @media (max-width: 1200px) {
    width: 90%;
    height: 600px;
    padding: 40px;

  }

  @media (max-width: 768px) {
    width: 95%;
    height: 500px;
    padding: 20px;
    margin 0 auto;
  }

  @media (max-width: 480px) {
    width: 90%;
    height: 400px;
    padding: 10px;
    justify-self: center;
    margin: 0 auto;
  }
`;

// Styled component for the title
const MenuTitle = styled.span`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.64px;
  text-align: center;
  color: black;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

// Styled component for the category items
const CategoryWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// Styled component for each item
const MenuItem = styled.div`
  margin-bottom: 30px;

  p {
    font-size: 18px;
    color: #b88454;
    font-weight: bold;
    text-align: center;
    margin: 0;

    &:last-child {
      font-style: italic;
      font-size: 16px;
      color: black;
      margin-top: 5px;

      @media (max-width: 768px) {
        font-size: 14px;
      }

      @media (max-width: 480px) {
        font-size: 12px;
      }
    }

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
`;

const MenuSection = ({ fields }) => {
  return (
    <MenuWrapper>
      <MenuTitle>Menu</MenuTitle>
      <CategoryWrapper>
        {fields.map((item) => (
          <MenuItem key={item.id}>
            <p>{item.title}</p>
            <p>{item.description}</p>
          </MenuItem>
        ))}
      </CategoryWrapper>
    </MenuWrapper>
  );
};

export default MenuSection;

import React, { useState, useRef, useEffect } from 'react';
import '@arcgis/core/assets/esri/themes/light/main.css';
import MapView from '@arcgis/core/views/MapView';
import WebMap from '@arcgis/core/WebMap';
import Locate from '@arcgis/core/widgets/Locate';
import Graphic from '@arcgis/core/Graphic';
import { Point } from '@arcgis/core/geometry';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import Search from "@arcgis/core/widgets/Search.js";

const INITIAL_POSITION = {
  lat: 31.7917,
  lng: -7.9926
};

const LocationPicker = ({ onClose, onSelectLocation }) => {
  const [position, setPosition] = useState(INITIAL_POSITION);
  const mapRef = useRef(null);
  const viewRef = useRef(null);
  const markerRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      const webMap = new WebMap({
        portalItem: { id: 'fae788aa91e54244b161b59725dcbb2a' }
      });

      const view = new MapView({
        container: mapRef.current,
        map: webMap,
        center: [position.lng, position.lat],
        zoom: 4
      });

      const searchWidget = new Search({
        view: view
      });

      view.ui.add(searchWidget, {
        position: "top-right",
        index: 2
      });

      viewRef.current = view;

      const locateWidget = new Locate({
        view: view,
        useHeadingEnabled: true
      });

      view.ui.add(locateWidget, {
        position: 'top-right'
      });

      locateWidget.on("locate", (newLocation) => {
        const { latitude, longitude } = newLocation.position.coords;
        setPosition({ lat: latitude, lng: longitude });
        moveMarkerTo({ latitude, longitude });
        view.goTo({ center: [longitude, latitude], zoom: 17 });
      });

      searchWidget.on("select-result", (event) => {
        const { latitude, longitude } = event.result.feature.geometry;
        moveMarkerTo({ latitude, longitude });
        setPosition({ lat: latitude, lng: longitude });
        view.goTo({ center: [longitude, latitude], zoom: 17 });
      });

      function moveMarkerTo({ latitude, longitude }) {
        if (markerRef.current) {
          markerRef.current.geometry = new Point({
            longitude: longitude,
            latitude: latitude
          });
        } else {
          markerRef.current = new Graphic({
            geometry: new Point({
              longitude: longitude,
              latitude: latitude
            }),
            symbol: new SimpleMarkerSymbol({
              color: 'red',
              size: '16px'
            })
          });
          view.graphics.add(markerRef.current);
        }

        
        if (textRef.current) {
          textRef.current.geometry = new Point({
            longitude: longitude,
            latitude: latitude - 0.0001 
          });
        } else {
          textRef.current = new Graphic({
            geometry: new Point({
              longitude: longitude,
              latitude: latitude - 0.0001
            }),
            symbol: new TextSymbol({
              text: "Déplacer pour préciser votre position",
              font: {
                size: 12,
                family: "sans-serif"
              },
              color: "black",
              haloColor: "white",
              haloSize: "1px",
              xoffset: 0,
              yoffset: -7, 
              horizontalAlignment: "center"
            })
          });
          view.graphics.add(textRef.current);
        }
      }

      view.on("click", (event) => {
        const { latitude, longitude } = event.mapPoint;
        setPosition({ lat: latitude, lng: longitude });
        moveMarkerTo({ latitude, longitude });
      });

      return () => {
        view.destroy();
      };
    }
  }, []);

  const handleConfirm = () => {
    const locationString = `${position.lat},${position.lng}`;
    if (onSelectLocation) {
      onSelectLocation(locationString);
    }
    onClose();
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2
    }}>
      <div style={{
        background: '#fff',
        padding: '20px',
        borderRadius: '8px',
        position: 'relative',
        width: '80vw',
        maxWidth: '800px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        height: '80vh'
      }}>
        <div id="map" style={{
          width: '100%',
          height: '100%',
          flex: '1'
        }} ref={mapRef}></div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '80px',
          marginTop: '10px'
        }}>
          <button
            style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
              backgroundColor: '#4CAF50',
              color: 'white'
            }}
            onClick={handleConfirm}
          >
            Valider
          </button>
          <button
            style={{
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
              backgroundColor: '#f44336',
              color: 'white'
            }}
            onClick={onClose}
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationPicker;

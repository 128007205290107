import React, { useState } from "react";
import { Form, Input } from "antd";

const { TextArea } = Input;

const formItemStyle = {
  marginBottom: "40px", // Spacing between form items
};

const labelStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "12px",
  color: "#7C7C7C",
  marginBottom: "-2px", // Spacing between the label and the TextArea
};

const textareaStyle = {
  height: "150px", // Height of the TextArea
  transition: "border-color 0.3s ease",
};

const InformationsComplementaires = ({ annonceData, setAnnonceData }) => {
  const [hovered, setHovered] = useState(false);

  const textAreaStyle = {
    ...textareaStyle,
    borderColor: hovered ? "#d7a022" : undefined,
  };

  // Handler for TextArea change
  const handleChange = (key) => (e) => {
    setAnnonceData({ ...annonceData, [key]: e.target.value });
  };

  return (
    <Form
      layout="vertical"
      initialValues={{ remember: true }}
      onFinish={(values) => console.log(values)}
    >
      <Form.Item
        style={formItemStyle}
        label={<span style={labelStyle}>Inspiration</span>}
        name="inspiration"
      >
        <TextArea
          style={textAreaStyle}
          placeholder="Qu'est-ce qui vous a inspiré à créer cette expérience ?&#10;Y a-t-il une histoire ou une tradition particulière derrière les plats que vous servez ?"
          rows={5}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          value={annonceData.inspiration || ''}
          onChange={handleChange('inspiration')}
        />
      </Form.Item>

      <Form.Item
        style={formItemStyle}
        label={<span style={labelStyle}>Durée de l'Expérience</span>}
        name="dureeExperience"
      >
        <TextArea
          style={textAreaStyle}
          placeholder="Combien de temps dure l'expérience du début à la fin ?&#10;Incluez-vous des activités ou des démonstrations en plus du repas ?"
          rows={5}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          value={annonceData.dureeExperience || ''}
          onChange={handleChange('dureeExperience')}
        />
      </Form.Item>

      <Form.Item
        style={formItemStyle}
        label={<span style={labelStyle}>Interaction</span>}
        name="interaction"
      >
        <TextArea
          style={textAreaStyle}
          placeholder="Comment interagissez-vous avec les invités pendant l'expérience ?&#10;Y a-t-il des moments spécifiques pour les questions, des démonstrations culinaires ou des activités interactives ?"
          rows={5}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          value={annonceData.intercation || ''}
          onChange={handleChange('intercation')}
        />
      </Form.Item>

      <Form.Item
        style={formItemStyle}
        label={<span style={labelStyle}>Précautions et accessibilité</span>}
        name="precautions"
      >
        <TextArea
          style={textAreaStyle}
          placeholder="Quelles mesures de sécurité prenez-vous, quels sont les atouts et accessibilités ?"
          rows={5}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          value={annonceData.precautions || ''}
          onChange={handleChange('precautions')}
        />
      </Form.Item>

      <Form.Item
        style={formItemStyle}
        label={<span style={labelStyle}>Bloc Réserver</span>}
        name="blocReserver"
      >
        <TextArea
          style={textAreaStyle}
          placeholder="Quelle est la procédure pour réserver une place dans votre expérience ?&#10;Y a-t-il un délai minimum avant la date de l'événement pour faire une réservation ?"
          rows={5}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          value={annonceData.blocReserver || ''}
          onChange={handleChange('blocReserver')}
        />
      </Form.Item>
    </Form>
  );
};

export default InformationsComplementaires;

import React, { useState } from 'react';
import { Layout, Menu, Typography, Drawer, Button } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    DashboardOutlined,
    TeamOutlined,
    SettingOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import Hotes from './gestionhote/Hotes';
import Experiences from './gestionexperience/Experiences';
import AdminDashboard from './AdminDashboard';
const { Header, Sider, Content } = Layout;

const Admin = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [mobileVisible, setMobileVisible] = useState(false);
    const [choice, setChoice] = useState('Dashboard');

    const handleMenuClick = (menuKey) => {
        setChoice(menuKey);
        setMobileVisible(false); // Close drawer for mobile
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const menuItems = [
        { label: 'Dashboard', key: 'Dashboard', icon: <DashboardOutlined /> },
        { label: 'Hotes', key: 'Hotes', icon: <TeamOutlined /> },
        { label: 'Experiences', key: 'Experiences', icon: <TeamOutlined /> },
        { label: 'Settings', key: 'Settings', icon: <SettingOutlined /> },
        { label: 'Logout', key: 'Logout', icon: <LogoutOutlined /> },
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            {/* Sidebar for Desktop */}
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={toggleCollapsed}
                breakpoint="lg"
                collapsedWidth="0"
                trigger={null}
            >
                <div style={{ textAlign: 'center', padding: '16px', color: '#fff' }}>
                    <Typography.Title level={4} style={{ color: '#fff', margin: 0 }}>
                        Admin Panel
                    </Typography.Title>
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['Dashboard']}
                    items={menuItems}
                    onClick={(e) => handleMenuClick(e.key)}
                />
            </Sider>

            <Layout>
                {/* Header */}
                <Header style={{ background: '#fff', padding: '0 16px', display: 'flex', alignItems: 'center' }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => (window.innerWidth < 768 ? setMobileVisible(true) : toggleCollapsed())}
                        style={{ marginRight: '16px' }}
                    />
                    <Typography.Title level={5} style={{ margin: 0 }}>
                        Admin Dashboard
                    </Typography.Title>
                </Header>

                {/* Content */}
                <Content style={{ margin: '16px' }}>
                    {choice === 'Dashboard' && <AdminDashboard />}
                    {choice === 'Hotes' && <Hotes />}
                    {choice === 'Experiences' && <Experiences />}
                    {choice === 'Settings' && <Typography.Text>Settings Page</Typography.Text>}
                    {choice === 'Logout' && <Typography.Text>You have logged out.</Typography.Text>}
                </Content>
            </Layout>

            {/* Mobile Drawer */}
            <Drawer
                title="Admin Panel"
                placement="left"
                closable
                onClose={() => setMobileVisible(false)}
                open={mobileVisible}
                bodyStyle={{ padding: 0 }}
            >
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['Dashboard']}
                    items={menuItems}
                    onClick={(e) => handleMenuClick(e.key)}
                />
            </Drawer>
        </Layout>
    );
};

export default Admin;

import React, { useState, useEffect } from 'react';
import { 
  Row, 
  Col, 
  Card, 
  Statistic, 
  Calendar, 
  Modal, 
  Button, 
  DatePicker, 
  Form, 
  Select, 
  Table,
  TimePicker,
  Input,
  message,
  Switch,
  Radio,
  Tabs,
  Tooltip,
  Badge,
  Space,
  Popconfirm,
  Alert
} from 'antd';
import { 
  ClockCircleOutlined, 
  CalendarOutlined,
  UserOutlined,
  EnvironmentOutlined,
  DollarOutlined,
  PauseCircleOutlined,
  SwapOutlined,
  ScheduleOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CopyOutlined
} from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

const ExperienceAvailability = () => {
  const [experiences, setExperiences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState('update'); // 'update', 'pause', 'reschedule', 'recurring'
  const [recurringSchedules, setRecurringSchedules] = useState([]);
  const [pausedExperiences, setPausedExperiences] = useState([]);
  const [form] = Form.useForm();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    fetchExperiences();
    fetchRecurringSchedules();
    fetchPausedExperiences();
  }, []);

  const fetchExperiences = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_SER}experience/hostn`, // Base API URL + endpoint
        {
          params: {
            f: 0, // Start index
            e: 120, // End index
          },
          headers: {
            Authorization: `Bearer ${keycloak.token}`, // Include Bearer token if needed
          },
        }
      );
      setExperiences(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching experiences:", error);
      message.error("Failed to load experiences");
      setLoading(false);
    }
  };

  const fetchRecurringSchedules = async () => {
    // Implement API call to fetch recurring schedules
  };

  const fetchPausedExperiences = async () => {
    // Implement API call to fetch paused experiences
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'titre',
      key: 'titre',
      render: (text, record) => (
        <div>
          <div className="font-semibold">
            {text}
            {record.isPaused && (
              <Tooltip title="Experience Paused">
                <PauseCircleOutlined className="ml-2 text-yellow-500" />
              </Tooltip>
            )}
          </div>
          <div className="text-sm text-gray-500">{record.typeCuisine}</div>
        </div>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'lieu',
      key: 'lieu',
      render: (text) => (
        <span>
          <EnvironmentOutlined className="mr-2" />
          {text}
        </span>
      ),
    },
    {
      title: 'Next Available',
      dataIndex: 'temps',
      key: 'temps',
      render: (text, record) => (
        <span>
          {moment(text).format('DD MMM YYYY')}
          <br />
          {record.heurDebut} - {record.heurFin}
        </span>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => (
        <Badge 
          status={record.isPaused ? 'warning' : record.isVerified ? 'success' : 'default'} 
          text={record.isPaused ? 'Paused' : record.isVerified ? 'Active' : 'Inactive'} 
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="Gérer la disponibilité">
            <Button 
              type="primary"
              icon={<ScheduleOutlined />}
              onClick={() => handleManageAvailability(record, 'update')}
            />
          </Tooltip>
          <Tooltip title="Pause temporaire">
            <Button 
              icon={<PauseCircleOutlined />}
              onClick={() => handleManageAvailability(record, 'pause')}
            />
          </Tooltip>
          <Tooltip title="Replanifier">
            <Button 
              icon={<SwapOutlined />}
              onClick={() => handleManageAvailability(record, 'reschedule')}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleManageAvailability = (experience, type) => {
    setSelectedExperience(experience);
    setModalType(type);
    
    if (type === 'update') {
      form.setFieldsValue({
        heureDebut: moment(experience.heurDebut, 'HH:mm'),
        heureFin: moment(experience.heurFin, 'HH:mm'),
        nombreMaxInvite: experience.nombreMaxInvite,
      });
    }
    
    setIsModalVisible(true);
  };

  const handleUpdateAvailability = async (values) => {
    try {
      const updatedExperience = {
        ...selectedExperience,
        heurDebut: values.heureDebut.format('HH:mm'),
        heureFin: values.heureFin.format('HH:mm'),
        nombreMaxInvite: values.nombreMaxInvite,
        temps: values.date.format('YYYY-MM-DD'),
      };

      await axios.put(
        `${process.env.REACT_APP_API_SER}experience/${selectedExperience.id}`,
        updatedExperience
      );

      message.success("Disponibilité de l'expérience mise à jour avec succès");
      setIsModalVisible(false);
      fetchExperiences();
    } catch (error) {
      console.error('Error updating experience:', error);
      message.error("Échec de la mise à jour de la disponibilité de l'expérience");
    }
  };

  const handlePauseExperience = async (values) => {
    try {
      const pauseData = {
        experienceId: selectedExperience.id,
        startDate: values.pauseRange[0].format('YYYY-MM-DD'),
        endDate: values.pauseRange[1].format('YYYY-MM-DD'),
        reason: values.pauseReason,
        notifyCustomers: values.notifyCustomers,
      };

      // Implement API call to pause experience
      await axios.post(
        `${process.env.REACT_APP_API_SER}experience/pause`,
        pauseData
      );

      message.success();
      setIsModalVisible(false);
      fetchExperiences("Expérience mise en pause avec succès");
    } catch (error) {
      console.error('Error pausing experience:', error);
      message.error("Échec de la mise en pause de l'expérience");
    }
  };

  const handleRescheduleExperience = async (values) => {
    try {
      const rescheduleData = {
        experienceId: selectedExperience.id,
        oldDate: selectedExperience.temps,
        newDate: values.newDate.format('YYYY-MM-DD'),
        newStartTime: values.newStartTime.format('HH:mm'),
        newEndTime: values.newEndTime.format('HH:mm'),
        reason: values.rescheduleReason,
        notifyCustomers: values.notifyCustomers,
      };

      // Implement API call to reschedule experience
      await axios.post(
        `${process.env.REACT_APP_API_SER}experience/reschedule`,
        rescheduleData
      );

      message.success("L'expérience a été reprogrammée avec succès");
      setIsModalVisible(false);
      fetchExperiences();
    } catch (error) {
      console.error('Error rescheduling experience:', error);
      message.error("Échec de la reprogrammation de l'expérience");
    }
  };

  const renderModalContent = () => {
    switch (modalType) {
      case 'pause':
        return (
          <Form
            form={form}
            onFinish={handlePauseExperience}
            layout="vertical"
          >
            <Alert
              message="Temporary Pause"
              description="Mettre en pause votre expérience la rendra indisponible pour les réservations pendant la période sélectionnée. Les réservations existantes seront notifiées."
              type="warning"
              showIcon
              className="mb-4"
            />
            
            <Form.Item
              name="pauseRange"
              label="Durée de la pause"
              rules={[{ required: true, message: 'Please select pause duration' }]}
            >
              <RangePicker className="w-full" />
            </Form.Item>

            <Form.Item
              name="pauseReason"
              label="Raison de la pause"
              rules={[{ required: true, message:  "Veuillez fournir une raison"}]}
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item
              name="notifyCustomers"
              valuePropName="checked"
            >
              <Switch checkedChildren="Notify customers" unCheckedChildren="Don't notify" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" danger htmlType="submit" className="w-full">
                Pause Experience
              </Button>
            </Form.Item>
          </Form>
        );

        case 'reschedule':
          return (
            <Form
              form={form}
              onFinish={handleRescheduleExperience}
              layout="vertical"
            >
              <Alert
                message="Replanifier l'expérience"
                description="Le replanification déplacera toutes les réservations vers la nouvelle date et heure. Les clients seront notifiés du changement."              
                type="info"
                showIcon
                className="mb-4"
              />
  
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Date actuelle"
                  >
                    <Input 
                      disabled 
                      value={moment(selectedExperience?.temps).format('DD MMM YYYY')} 
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="newDate"
                    label="Nouvelle date"
                    rules={[{ required: true, message: 'Sélectionnez une nouvelle date' }]}
                  >
                    <DatePicker className="w-full" />
                  </Form.Item>
                </Col>
              </Row>
  
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="newStartTime"
                    label="Nouvelle heure de début"
                    rules={[{ required: true, message: 'Sélectionnez l\'heure de début' }]}
                  >
                    <TimePicker format="HH:mm" className="w-full" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="newEndTime"
                    label="Nouvelle heure de fin"
                    rules={[{ required: true, message: 'Sélectionnez l\'heure de fin' }]}
                  >
                    <TimePicker format="HH:mm" className="w-full" />
                  </Form.Item>
                </Col>
              </Row>
  
              <Form.Item
                name="rescheduleReason"
                label="Raison du changement"
                rules={[{ required: true, message: 'Veuillez fournir une raison' }]}
              >
                <TextArea rows={4} />
              </Form.Item>
  
              <Form.Item
                name="notifyCustomers"
                valuePropName="checked"
                initialValue={true}
              >
                <Switch checkedChildren="Notifier les clients" unCheckedChildren="Ne pas notifier" defaultChecked />
              </Form.Item>
  
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-full">
                  Confirmer la replanification
                </Button>
              </Form.Item>
            </Form>
          );
  
          default:
            return (
              <Form
                form={form}
                onFinish={handleUpdateAvailability}
                layout="vertical"
              >
                <Tabs defaultActiveKey="single">
                  <TabPane tab="Date Unique" key="single">
                    <Form.Item
                      name="date"
                      label="Date"
                      rules={[{ required: true, message: 'Veuillez sélectionner une date' }]}
                    >
                      <DatePicker className="w-full" />
                    </Form.Item>
    
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="heureDebut"
                          label="Heure de début"
                          rules={[{ required: true, message: 'Veuillez sélectionner l\'heure de début' }]}
                        >
                          <TimePicker format="HH:mm" className="w-full" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="heureFin"
                          label="Heure de fin"
                          rules={[{ required: true, message: 'Veuillez sélectionner l\'heure de fin' }]}
                        >
                          <TimePicker format="HH:mm" className="w-full" />
                        </Form.Item>
                      </Col>
                    </Row>
    
                    <Form.Item
                      name="nombreMaxInvite"
                      label="Nombre maximum d'invités"
                      rules={[{ required: true, message: 'Veuillez entrer le nombre maximum d\'invités' }]}
                    >
                      <Input type="number" min={1} />
                    </Form.Item>
                  </TabPane>
    
                  <TabPane tab="Planning récurrent" key="recurring">
                    <Form.Item
                      name="recurringDates"
                      label="Sélectionner les dates"
                      rules={[{ required: true, message: 'Veuillez sélectionner des dates' }]}
                    >
                      <RangePicker className="w-full" />
                    </Form.Item>
    
                    <Form.Item
                      name="recurringDays"
                      label="Jours récurrents"
                      rules={[{ required: true, message: 'Veuillez sélectionner des jours' }]}
                    >
                      <Select mode="multiple" placeholder="Sélectionner les jours">
                        <Option value="monday">Lundi</Option>
                        <Option value="tuesday">Mardi</Option>
                        <Option value="wednesday">Mercredi</Option>
                        <Option value="thursday">Jeudi</Option>
                        <Option value="friday">Vendredi</Option>
                        <Option value="saturday">Samedi</Option>
                        <Option value="sunday">Dimanche</Option>
                      </Select>
                    </Form.Item>
    
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name="recurringStartTime"
                          label="Heure de début"
                          rules={[{ required: true, message: 'Sélectionner l\'heure de début' }]}
                        >
                          <TimePicker format="HH:mm" className="w-full" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="recurringEndTime"
                          label="Heure de fin"
                          rules={[{ required: true, message: 'Sélectionner l\'heure de fin' }]}
                        >
                          <TimePicker format="HH:mm" className="w-full" />
                        </Form.Item>
                      </Col>
                    </Row>
    
                    <Form.Item
                      name="recurringMaxGuests"
                      label="Nombre maximum d'invités par session"
                      rules={[{ required: true, message: 'Entrez le nombre maximum d\'invités' }]}
                    >
                      <Input type="number" min={1} />
                    </Form.Item>
    
                    <Form.Item
                      name="breakDates"
                      label="Dates d\'exception (Jours fériés/Pause)"
                    >
                      <Select mode="multiple" placeholder="Sélectionner les dates à exclure">
                        {/* Ajouter des dates de vacances */}
                      </Select>
                    </Form.Item>
                  </TabPane>
    
                  <TabPane tab="Paramètres avancés" key="advanced">
                    <Form.Item
                      name="autoConfirm"
                      label="Confirmation automatique"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
    
                    <Form.Item
                      name="minimumNotice"
                      label="Préavis minimum de réservation"
                    >
                      <Select defaultValue="24">
                        <Option value="12">12 heures</Option>
                        <Option value="24">24 heures</Option>
                        <Option value="48">48 heures</Option>
                        <Option value="72">72 heures</Option>
                      </Select>
                    </Form.Item>
    
                    <Form.Item
                      name="cancellationPolicy"
                      label="Politique d'annulation"
                    >
                      <Select defaultValue="moderate">
                        <Option value="flexible">Flexible (24h)</Option>
                        <Option value="moderate">Modérée (3 jours)</Option>
                        <Option value="strict">Stricte (7 jours)</Option>
                      </Select>
                    </Form.Item>
    
                    <Form.Item
                      name="overbooking"
                      label="Marge de surréservation"
                    >
                      <Input type="number" min={0} addonAfter="invités" />
                    </Form.Item>
    
                    <Form.Item
                      name="preparationTime"
                      label="Temps de préparation nécessaire"
                    >
                      <Input type="number" min={0} addonAfter="heures" />
                    </Form.Item>
                  </TabPane>
    
                  <TabPane tab="Tarification & Promotions" key="pricing">
                    <Form.Item
                      name="seasonalPricing"
                      label="Tarification saisonnière"
                    >
                      <Select mode="multiple" placeholder="Ajouter des règles de tarification saisonnière">
                        <Option value="weekend">Supplément week-end (+10%)</Option>
                        <Option value="holiday">Supplément jours fériés (+20%)</Option>
                        <Option value="offpeak">Remise hors-saison (-15%)</Option>
                      </Select>
                    </Form.Item>
    
                    <Form.Item
                      name="groupDiscounts"
                      label="Réductions de groupe"
                    >
                      <Select mode="multiple" placeholder="Ajouter des règles de réduction de groupe">
                        <Option value="group5">5+ invités (5% de réduction)</Option>
                        <Option value="group10">10+ invités (10% de réduction)</Option>
                        <Option value="group15">15+ invités (15% de réduction)</Option>
                      </Select>
                    </Form.Item>
    
                    <Form.Item
                      name="lastMinute"
                      label="Tarification de dernière minute"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </TabPane>
                </Tabs>
    
                <Form.Item>
                  <Space className="w-full justify-end">
                    <Button onClick={() => setIsModalVisible(false)}>
                      Annuler
                    </Button>
                    <Button type="primary" htmlType="submit">
                      Sauvegarder les modifications
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            );
    
        }
  };

  const getModalTitle = () => {
    switch (modalType) {
      case 'pause':
        return 'Temporary Pause Experience';
      case 'reschedule':
        return 'Reschedule Experience';
      default:
        return 'Manage Experience Availability';
    }
  };

  return (
    <div className="p-6">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Experience Management" className="shadow-sm">
            <div className="mb-4">
              <Row gutter={16}>
                <Col span={6}>
                  <Statistic 
                    title="Total Experiences" 
                    value={experiences.length}
                    prefix={<CalendarOutlined />}
                  />
                </Col>
                <Col span={6}>
                  <Statistic 
                    title="Active Experiences" 
                    value={experiences.filter(exp => exp.isVerified && !exp.isPaused).length}
                    prefix={<CheckCircleOutlined />}
                  />
                </Col>
                <Col span={6}>
                  <Statistic 
                    title="Paused Experiences" 
                    value={experiences.filter(exp => exp.isPaused).length}
                    prefix={<PauseCircleOutlined />}
                  />
                </Col>
                <Col span={6}>
                  <Statistic 
                    title="Scheduled Sessions" 
                    value={recurringSchedules.length}
                    prefix={<ScheduleOutlined />}
                  />
                </Col>
              </Row>
            </div>
            
            <Table 
              columns={columns}
              dataSource={experiences}
              loading={loading}
              rowKey="id"
              className="mt-4"
            />
          </Card>
        </Col>
      </Row>

      <Modal
        title={getModalTitle()}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default ExperienceAvailability;
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
function HostClient() {
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    email: "",
    password: "",
    acceptedTerms: false,
  });
  const navigate = useNavigate();
  
  const { keycloak } = useKeycloak();
  if(keycloak.authenticated){
    console.log("Authenticated");
    navigate("/");
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        const response = await axios.post(
          process.env.REACT_APP_API_SER+"auth/clientSignUp",
          formData
        );
        console.log("Response:", response.data);
        alert(`Bienvenue, votre inscription a été réussie !`);
        keycloak.login();
      }
    } catch (error) {
      console.error("Erreur:", error);
  
      // Message d'erreur personnalisé
      if (error.response) {
        if (error.response.status === 400) {
          alert("Erreur de requête : veuillez vérifier les informations que vous avez saisies.");
        } else if (error.response.status === 500) {
          alert("Erreur du serveur : veuillez réessayer plus tard.");
        } else {
          alert(`Erreur : ${error.response.status} - ${error.response.data.message}`);
        }
      } else if (error.request) {
        alert("Erreur réseau : impossible de joindre le serveur. Veuillez vérifier votre connexion.");
      } else {
        alert("Une erreur inattendue est survenue.");
      }
    }
  };
  

  const [errors, setErrors] = useState({
    nom: "",
    prenom: "",
    email: "",
    password: "",
    acceptedTerms: "",
  });

  // Function to handle input changes
  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === "checkbox" ? checked : value,
    }));
  };

  // Function to validate inputs
  const validate = () => {
    let formErrors = {};
    if (!formData.nom.trim()) formErrors.nom = "Le nom est requis.";
    if (!formData.prenom.trim()) formErrors.prenom = "Le prénom est requis.";
    if (!formData.email.trim()) {
      formErrors.email = "L'email est requis.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "L'email n'est pas valide.";
    }
    if (!formData.password) {
      formErrors.password = "Le mot de passe est requis.";
    } else if (formData.password.length < 6) {
      formErrors.password =
        "Le mot de passe doit comporter au moins 6 caractères.";
    }
    if (!formData.acceptedTerms) {
      formErrors.acceptedTerms = "Vous devez accepter les termes.";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Function to handle form submission

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white p-6 rounded shadow-md w-full">
      <div className="flex flex-col md:grid md:grid-cols-2 gap-6">
        <div>
          <input
            type="text"
            id="nom"
            placeholder="Nom*"
            value={formData.nom}
            onChange={handleChange}
            className={`mt-1 block w-full px-5 py-5 border ${
              errors.nom ? "border-red-500" : "border-gray-input"
            } rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg`}
          />
          {errors.nom && <p className="text-red-500 text-sm">{errors.nom}</p>}
        </div>
        <div>
          <input
            type="text"
            id="prenom"
            placeholder="Prénom*"
            value={formData.prenom}
            onChange={handleChange}
            className={`mt-1 block w-full px-5 py-5 border ${
              errors.prenom ? "border-red-500" : "border-gray-input"
            } rounded-md shadow-sm focus:outline-none focus:ring-orange-text focus:border-orange-text poppins-regular text-lg`}
          />
          {errors.prenom && (
            <p className="text-red-500 text-sm">{errors.prenom}</p>
          )}
        </div>
        <div>
          <input
            type="email"
            id="email"
            placeholder="Email*"
            value={formData.email}
            onChange={handleChange}
            className={`mt-1 block w-full px-5 py-5 border ${
              errors.email ? "border-red-500" : "border-gray-input"
            } rounded-md shadow-sm focus:outline-none focus:ring-orange-text focus:border-orange-text poppins-regular text-lg`}
          />
          {errors.email && (
            <p className="text-red-500 text-sm">{errors.email}</p>
          )}
        </div>
        <div>
          <input
            type="password"
            id="password"
            placeholder="Mot de passe*"
            value={formData.password}
            onChange={handleChange}
            className={`mt-1 block w-full px-5 py-5 border ${
              errors.password ? "border-red-500" : "border-gray-input"
            } rounded-md shadow-sm focus:outline-none focus:ring-orange-text focus:border-orange-text poppins-regular text-lg`}
          />
          {errors.password && (
            <p className="text-red-500 text-sm">{errors.password}</p>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="mt-6">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              id="acceptedTerms"
              checked={formData.acceptedTerms}
              onChange={handleChange}
              className="form-checkbox text-yellow-500 w-7 h-7 rounded-md"
            />
            <span className="ml-2 text-gray-700">
              J'accepte les{" "}
              <a href="#" className="text-gray-text poppins-medium">
                politiques et conditions d'utilisations
              </a>
            </span>
          </label>
          {errors.acceptedTerms && (
            <p className="text-red-500 text-sm">{errors.acceptedTerms}</p>
          )}
        </div>
        <div className="mt-6 ">
          <button
            type="submit"
            className="w-full md:w-auto bg-orange-text text-white py-2 px-4 rounded-md md:w-64 md:h-14 poppins-regular text-xl shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
            S'inscrire
          </button>
        </div>
      </div>
    </form>
  );
}

export default HostClient;
import React, { useRef, useEffect, useState } from "react";
import logoBright from "../img/Group.webp";
import logoDark from "../img/Group 2040.webp";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

function Nav({ Color }) {
  const textColor = Color === "black" ? "text-grey-text" : "text-white";
  const { keycloak } = useKeycloak();
  const handleLogin = () => {
    if(keycloak.authenticated){
      if(keycloak.hasRealmRole("Client")){
      navigate("/ClientProfile");
      }
      else if(keycloak.hasRealmRole("Profile")){
      navigate("/hostprofile");
      }
    }
    else{
      keycloak.login();
    }
  };
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [avatarImage, setAvatarImage] = useState("Profile");
  const menuRef = useRef(null);
  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const handleClick = () => {
    navigate("/");
  };
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_SER+"host/profile/getProfile",
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
            },
          }
        );
        console.log("Profile data:", response.data);
        setAvatarImage(process.env.REACT_APP_API_SER+"photos/"+ response.data.avatar); // Update the profile picture
        console.log("avatarImage",avatarImage);
        localStorage.setItem("avatar", avatarImage);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile(); // Call the async function
  }, [keycloak.token]);
  return (
    <>
      <nav className="flex items-center justify-between p-4 md:p-6 lg:p-8 absolute top-0 w-full z-20 bg-transparent">
        <img
          src={Color === "black" ? logoDark : logoBright}
          alt="Feast and Food Logo"
          className="h-12 md:h-14 lg:h-16"
          onClick={handleClick}
        />
        <div className="hidden md:flex space-x-4 lg:space-x-8 h-12">
          <button
            className={`${textColor} poppins-medium text-base md:text-lg`}
          >
            Expériences
          </button>
          <button
            className={`${textColor} poppins-medium  text-base md:text-lg`}
          >
            A propos
          </button>
          <button
            className={`${textColor} poppins-medium text-base md:text-lg`}
          >
            Contact
          </button>
          <button
            className={`${textColor} poppins-medium text-base md:text-lg`}
            onClick={handleLogin}
          >
            {
            keycloak.authenticated? "Profile":"Se connecter"
            }
          </button>
          {
            !keycloak.authenticated?
          <>
          <button
            className="bg-yellow-500 text-white poppins-medium text-base md:text-lg py-2 px-4 rounded"
            onClick={() => {
              navigate("/devenirhote");
            }}
          >
            Devenir client
          </button>
          <button
            className={`${textColor} poppins-medium  text-base md:text-lg`}
            onClick={() => {
              navigate("/inscription");
            }}
          >
            Devenir hôte
          </button>
          </>
          :<></>
          }
          { keycloak.authenticated?
            <button onClick={()=>navigate("/HostProfile")}
            className={`${textColor} poppins-medium text-base md:text-lg`}
            >
            <img
              src={avatarImage} // Replace with actual profile picture URL or component
              alt="Profile"
              className="h-8 w-8 md:w-16 md:h-16 rounded-full"
            />
          </button>
          :<></>
        }
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button
            onClick={openMenu}
            style={{
              background: "none",
              border: "none",
              color: "white",
              cursor: "pointer",
            }}
          >
            <MenuIcon style={{ fontSize: 32 }} />
          </button>
        </div>
      </nav>
      <div
        ref={menuRef}
        className="nav-menu"
        style={{
          position: "fixed",
          top: "0",
          right: "0",
          height: "100vh",
          width: "300px",
          backgroundColor: "white",
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          boxShadow: "-5px 0 15px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s ease-in-out",
          transform: isMenuOpen ? "translateX(0)" : "translateX(100%)",
        }}
      >
        <header
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
            borderBottom: "1px solid #e9ecef",
          }}
        >
          <button
            onClick={closeMenu}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <CloseIcon style={{ fontSize: 28, color: "#495057" }} />
          </button>
        </header>
        <nav style={{ flex: 1, overflowY: "auto" }}>
          <ul
            style={{
              listStyleType: "none",
              padding: "0",
              margin: "0",
            }}
          >
            {[
              { text: "Expériences", path: "/experience" },
              { text: "À propos", path: "/a-propos" },
              { text: "Contact", path: "/contact" },
              { text: "Se connecter", path: "/inscription" },
              { text: "S'inscrire", path: "/devenirhote" },
              { text: "Devenir hôte", path: "/inscription" },
            ].map((item, index) => (
              <li key={index}>
                <button
                  onClick={() => {
                    {
                      item.text === "Se connecter" ? handleLogin() :
                    navigate(item.path)
                    }
                    closeMenu();
                  }}
                  style={{
                    padding: "15px 25px",
                    textAlign: "left",
                    background: "none",
                    border: "none",
                    borderBottom: "1px solid #e9ecef",
                    cursor: "pointer",
                    fontSize: "16px",
                    color: "#212529",
                    transition: "background-color 0.2s",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#d7a022")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "transparent")
                  }
                >
                  {item.text}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Nav;

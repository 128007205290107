import React from 'react';
import { Card, Tag, Typography, Row, Col, Tooltip } from 'antd';
import { 
  CalendarOutlined, 
  ClockCircleOutlined, 
  DeleteOutlined 
} from '@ant-design/icons';

const { Text } = Typography;

const RecurrenceDisplay = ({ recurenceDates, onDelete }) => {
  // Helper function to format date
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  // Helper function to get day name translation
  const translateDay = (day) => {
    const days = {
      'Lundi': 'Lundi',
      'Mardi': 'Mardi',
      'Mercredi': 'Mercredi',
      'Jeudi': 'Jeudi',
      'Vendredi': 'Vendredi',
      'Samedi': 'Samedi',
      'Dimanche': 'Dimanche'
    };
    return days[day] || day;
  };

  return (
    <Card 
      title="Récurrences Programmées" 
      extra={<CalendarOutlined style={{ color: '#d7a022' }} />}
      style={{ 
        width: '100%', // Ensure full width
        borderRadius: '12px', 
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        marginTop: '20px'
      }}
    >
      {recurenceDates && recurenceDates.length > 0 ? (
        recurenceDates.map((recurrence, index) => (
          <Row 
            key={index} 
            align="middle" 
            justify="space-between"
            style={{ 
              width: '100%', // Ensure full width
              marginBottom: '15px', 
              padding: '10px',
              backgroundColor: '#f9f9f9',
              borderRadius: '8px'
            }}
          >
            <Col xs={24} sm={6}>
              <Tooltip title="Jour de la semaine">
                <Tag color="processing" icon={<CalendarOutlined />}>
                  {translateDay(recurrence.jour)}
                </Tag>
              </Tooltip>
            </Col>
            <Col xs={24} sm={8}>
              <Tooltip title="Période">
                <Text strong style={{ color: '#4a4a4a' }}>
                  <ClockCircleOutlined style={{ marginRight: '8px', color: '#d7a022' }} />
                  {recurrence.heurDebut} - {recurrence.heurFin}
                </Text>
              </Tooltip>
            </Col>
            <Col xs={24} sm={6}>
              <Tooltip title="Date de début">
                <Text type="secondary">
                  {formatDate(recurrence.dateDebut)}
                </Text>
              </Tooltip>
            </Col>
            <Col xs={24} sm={2}>
              {onDelete && (
                <Tooltip title="Supprimer cette récurrence">
                  <DeleteOutlined 
                    onClick={() => onDelete(index)} 
                    style={{ 
                      color: '#ff4d4f', 
                      cursor: 'pointer',
                      transition: 'color 0.3s'
                    }} 
                  />
                </Tooltip>
              )}
            </Col>
          </Row>
        ))
      ) : (
        <Text type="secondary" style={{ textAlign: 'center', display: 'block' }}>
          Aucune récurrence programmée
        </Text>
      )}
    </Card>
  );
};

export default RecurrenceDisplay;
import React from 'react';
import './ProfileHeader.css';

const ProfileHeader = ({ name, location, experienceYears, avatar }) => {
  return (
    <div className="profile-card">
      <div className="profile-header">
        <img src={`${process.env.REACT_APP_API_SER}photos/`+avatar || "/default-avatar.png"} alt={name} className="profile-image" />
        <div className="profile-info">
          <h2>{name}</h2>
          <p className="location">{location}</p>
          <p className="experience">{experienceYears}</p>
        </div>
      </div>
      <span className="message-icon" role="img" aria-label="message">
      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="send-01">
<path id="Icon" d="M16.2407 2.34436L8.44627 10.1388M3.23094 6.22236L15.3684 2.01139C16.1159 1.75207 16.833 2.46919 16.5737 3.21665L12.3627 15.3542C12.0742 16.1857 10.9066 16.2085 10.5859 15.3888L8.65856 10.4634C8.5623 10.2174 8.36768 10.0228 8.12168 9.92653L3.19624 7.99919C2.37664 7.67847 2.39944 6.51084 3.23094 6.22236Z" stroke="white" stroke-width="1.16946" stroke-linecap="round"/>
</g>
</svg>
      </span>
    </div>
  );
};

export default ProfileHeader;

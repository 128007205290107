import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Rating,
} from "@mui/material";

const Food = ({ image, titre, typeCuisine, prixParInvite, rating, description }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 2 }}>
      <Card sx={{ maxWidth: 345, border: "none", boxShadow: "none" }}>
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            image={`${process.env.REACT_APP_API_SER}photos/`+ image || "/default-food.png"}
            alt={titre}
          />
        </Box>

        <CardContent>
          <Typography variant="subtitle1" fontWeight="bold">
            {titre} - {typeCuisine}
          </Typography>

          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>

          {/* Price section */}
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6" color="#D7A022" fontWeight="500">
              {prixParInvite} MAD
            </Typography>
          </Box>

          {/* Rating section */}
          <Box display="flex" alignItems="center" mt={1}>
            <Rating name="read-only" value={rating} readOnly />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Food;

import React, { useState, useEffect } from "react";

const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const questions = [
    {
      question: "Comment puis-je gérer mes disponibilités en tant qu'hôte?",
      answer:
        "Vous pourrez facilement gérer vos disponibilités en utilisant notre calendrier interactif. Il vous suffira de définir vos créneaux de disponibilité.",
    },
    {
      question:
        "Comment puis-je communiquer avec les utilisateurs avant la réservation ?",
      answer:
        "Utilisez notre système de messagerie intégré pour communiquer avec les utilisateurs avant la réservation.",
    },
    {
      question:
        "Comment puis-je utiliser les retours des utilisateurs pour améliorer mes services ?",
      answer:
        "Analysez les commentaires et les notes laissés par les utilisateurs après chaque expérience pour identifier les points d'amélioration.",
    },
    {
      question:
        "Comment puis-je personnaliser mon profil culinaire en tant qu'hôte?",
      answer:
        "Accédez à votre profil et utilisez les options de personnalisation pour ajouter vos spécialités, photos et description détaillée.",
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "#F2F2F2",
        fontFamily: "Arial, sans-serif",
        width: "100%",
        margin: "0 auto",
        padding: isMobile ? "10vw 5vw" : "5vw",
      }}
    >
      <h2
        style={{
          fontFamily: "Poppins",
          fontSize: isMobile ? "6vw" : "3.5vw",
          fontWeight: "600",
          marginBottom: isMobile ? "8vw" : "4vw",
          marginLeft: "-0.5vw",
          marginTop: "-1vw",
        }}
      >
        Des réponses à vos questions
      </h2>

      {questions.map((item, index) => (
        <div
          key={index}
          style={{
            backgroundColor: "white",
            padding: isMobile ? "4vw" : "2vw",
            marginBottom: isMobile ? "3vw" : "1vw",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: isMobile ? "2vw" : "1vw",
            }}
            onClick={() => toggleExpand(index)}
          >
            <h3
              style={{
                display: "flex",
                alignItems: "flex-start",
                fontFamily: "Poppins",
                fontSize: isMobile ? "4vw" : "2vw",
                fontWeight: "400",
                lineHeight: isMobile ? "5vw" : "2.5vw",
                marginBottom: isMobile ? "2vw" : "1vw",
              }}
            >
              <span
                style={{
                  color: "#9d9da1",
                  marginRight: isMobile ? "2vw" : "1vw",
                  marginTop: "0.2vw",
                  fontFamily: "Inter",
                  fontSize: isMobile ? "5vw" : "3vw",
                  fontWeight: 700,
                  lineHeight: isMobile ? "4vw" : "2vw",
                  letterSpacing: "0.0625vw",
                  textAlign: "left",
                }}
              >
                {String(index + 1).padStart(2, "0")}
              </span>
              {item.question}
            </h3>
            <button
              style={{
                backgroundColor: expandedIndex === index ? "black" : "#F2F2F2",
                borderRadius: isMobile ? "50%" : "50%", // Ensure it's round in mobile
                aspectRatio: "1 / 1",
                width: isMobile ? "12vw" : "4vw",
                height: isMobile ? "6vw" : "4vw",
                border: "none",
                fontSize: isMobile ? "4vw" : "2.2vw",
                cursor: "pointer",
                color: expandedIndex === index ? "white" : "black",
                transform: expandedIndex === index ? "rotate(45deg)" : "none",
                transition: "transform 0.3s ease",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              +
            </button>
          </div>
          {expandedIndex === index && (
            <p
              style={{
                fontFamily: "Poppins",
                color: "rgba(60, 60, 67, 0.85)",
                fontWeight: 200,
                fontSize: isMobile ? "3.2vw" : "1.6vw",
                marginLeft: isMobile ? "8vw" : "4vw",
                lineHeight: "1.6",
                marginTop: isMobile ? "2vw" : "1vw",
                maxWidth: "84%",
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              {item.answer}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;

import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  useMediaQuery,
  Divider,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import ReservationPopup from "./Popup";

const ReservationCardMobile = ({ e, onClick }) => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Utiliser la taille d'écran mobile

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        mb: 0,
        position: "fixed",
        bottom: 0, // Ajuste cette valeur si nécessaire pour aligner correctement
        left: 0,
        width: "100%",
        bgcolor: "background.paper",
        boxShadow: 1,
        padding: 2,
        zIndex: 1000,
      }}
    >
      <Box sx={{ flexGrow: 0.2 }}>
        <Typography style={{ fontWeight: "600", fontFamily: "Poppins" }}>
          {e.prixParInvite} MAD
        </Typography>
        <Typography
          style={{
            fontWeight: "400",
            fontFamily: "Poppins",
            fontSize: "12px",
          }}
        >
          / par invité
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ flexGrow: 1 }}>
        <center>
          <Typography variant="body2">{e.date || "13 Mai"}</Typography>

          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.13542 13.6326V13.5714M9.69792 13.6326V13.5714M9.69792 10.3143V10.2531M12.8646 10.3143V10.2531M3.76042 7.05712H14.8437M5.19296 2.375V3.59657M13.2604 2.375V3.59642M13.2604 3.59642H5.34375C4.03207 3.59642 2.96875 4.69012 2.96875 6.03927V14.1822C2.96875 15.5313 4.03207 16.625 5.34375 16.625H13.2604C14.5721 16.625 15.6354 15.5313 15.6354 14.1822L15.6354 6.03927C15.6354 4.69012 14.5721 3.59642 13.2604 3.59642Z"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </center>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box sx={{ textAlign: "center", flexGrow: 1 }}>
        <center>
          <Typography variant="body2">{e.guests || 2}</Typography>
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.90002 14.4216C2.76978 12.9709 4.67601 11.978 7.64999 11.978C10.624 11.978 12.5302 12.9709 13.4 14.4216M13.9502 10.8779C15.5251 11.6654 16.3126 12.4529 17.1 14.0278M12.3122 4.85893C13.0533 5.25758 13.557 6.04035 13.557 6.94078C13.557 7.8152 13.0819 8.57866 12.3758 8.98712M10.0124 6.94076C10.0124 8.24547 8.9547 9.30314 7.64999 9.30314C6.34528 9.30314 5.2876 8.24547 5.2876 6.94076C5.2876 5.63605 6.34528 4.57837 7.64999 4.57837C8.9547 4.57837 10.0124 5.63605 10.0124 6.94076Z"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        </center>
      </Box>

      <Button
        variant="contained"
        sx={{
          bgcolor: "#E3B13E",
          color: "white",
          fontWeight: 600,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          textTransform: "uppercase",
          flexGrow: 1,
          width: "15%",
          fontSize: "0.7rem",
          fontFamily: "Poppins",
        }}
        onClick={handleClickOpen} // Appelle la fonction onClick pour gérer la réservation
      >
        Réserver
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            boxShadow: "none",
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogContent>
          <ReservationPopup /> {/* Affichez le composant `Popup` ici */}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ReservationCardMobile;

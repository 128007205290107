import React, { useState, useEffect } from 'react';
import { 
  Row, 
  Col, 
  Card, 
  Statistic, 
  Calendar, 
  Modal, 
  Button, 
  DatePicker, 
  Form, 
  Select, 
  Typography, 
  Table,
  Tag,
  Tooltip,
  Progress,
  Divider,
  message
} from 'antd';
import { 
  CalendarOutlined,
  CheckCircleOutlined, 
  ClockCircleOutlined,
  StopOutlined, 
  DollarOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/fr';
import NavHote from '../../Host/NavHote';
import ExperienceAvailability from './ExperienceAvailability';
import { useKeycloak } from '@react-keycloak/web';
import HostExperiences from './HostExperiences';

moment.locale('fr');

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Option } = Select;

function TableauDeBordHote() {
  const { keycloak } = useKeycloak();
  const [availabilities, setAvailabilities] = useState([]);
  const [editingAvailability, setEditingAvailability] = useState(null);
  const [isAvailabilityModalVisible, setAvailabilityModalVisible] = useState(false);
  const [statistiques, setStatistiques] = useState({
    reservationsTotales: 0,
    reservationsApprouvees: 0,
    reservationsEnAttente: 0,
    revenuTotal: 0,
    tauxOccupation: 0
  });

  // Fetch availabilities
  useEffect(() => {
    const fetchAvailabilities = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_SER}disponibility/host`, {
          headers: {
            'Authorization': `Bearer ${keycloak.token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch availabilities');
        }

        const data = await response.json();
        setAvailabilities(data);
      } catch (error) {
        message.error('Erreur lors du chargement des disponibilités');
        console.error('Error fetching availabilities:', error);
      }
    };

    fetchAvailabilities();
  }, [keycloak.token]);

  // Validate unique dates
  const isDateRangeUnique = (newStart, newEnd) => {
    return !availabilities.some(avail => 
      moment(newStart).isBetween(avail.dateDebut, avail.dateFin, null, '[]') ||
      moment(newEnd).isBetween(avail.dateDebut, avail.dateFin, null, '[]') ||
      moment(avail.dateDebut).isBetween(newStart, newEnd, null, '[]')
    );
  };

  // Save availability
  const saveDisponibility = async (values) => {
    const newStart = values.plageDate[0].format('YYYY-MM-DD');
    const newEnd = values.plageDate[1].format('YYYY-MM-DD');

    // Check for unique date range
    if (!isDateRangeUnique(newStart, newEnd) && !editingAvailability) {
      message.error('Cette plage de dates chevauche une disponibilité existante');
      return;
    }

    const nouvelleDisponibilite = {
      dateDebut: newStart,
      dateFin: newEnd,
      type: values.type
    };

    try {
      const url = editingAvailability 
        ? `${process.env.REACT_APP_API_SER}disponibility/edit/${editingAvailability.id}`
        : `${process.env.REACT_APP_API_SER}disponibility/add`;
      
      const method = editingAvailability ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${keycloak.token}`
        },
        body: JSON.stringify(nouvelleDisponibilite),
      });

      if (!response.ok) {
        throw new Error('Échec de la sauvegarde');
      }

      const updatedAvailability = await response.json();
      
      if (editingAvailability) {
        setAvailabilities(availabilities.map(dispo => 
          dispo.id === editingAvailability.id ? updatedAvailability : dispo
        ));
      } else {
        setAvailabilities([...availabilities, updatedAvailability]);
      }

      message.success('Disponibilité enregistrée avec succès');
      setAvailabilityModalVisible(false);
      setEditingAvailability(null);
    } catch (error) {
      message.error('Erreur lors de l\'enregistrement');
      console.error('Error saving availability:', error);
    }
  };

  // Delete availability
  const deleteDisponibility = async (disponibiliteASupprimer) => {
    Modal.confirm({
      title: 'Suppression de disponibilité',
      content: 'Êtes-vous sûr de vouloir supprimer cette disponibilité ?',
      okText: 'Confirmer',
      cancelText: 'Annuler',
      async onOk() {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_SER}disponibility/delete/${disponibiliteASupprimer.id}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${keycloak.token}`
            },
          });

          if (!response.ok) {
            throw new Error('Échec de la suppression');
          }

          setAvailabilities(availabilities.filter(
            dispo => dispo.id !== disponibiliteASupprimer.id
          ));
          message.success('Disponibilité supprimée avec succès');
        } catch (error) {
          message.error('Erreur lors de la suppression');
          console.error('Error deleting availability:', error);
        }
      }
    });
  };

  // Render availability columns
  const colonnesDisponibilites = [
    {
      title: 'Date Début',
      dataIndex: 'dateDebut',
      key: 'dateDebut',
      render: (text) => moment(text).format('DD MMMM YYYY')
    },
    {
      title: 'Date Fin',
      dataIndex: 'dateFin',
      key: 'dateFin',
      render: (text) => moment(text).format('DD MMMM YYYY')
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <Tag color={
          type === 'BLOQUE' ? 'red' : 
          type === 'JOURNEE_PARTIELLE' ? 'orange' : 'green'
        }>
          {type==='BLOQUE' ? <StopOutlined /> : type==='JOURNEE_PARTIELLE' ? <ClockCircleOutlined /> : <CheckCircleOutlined />}
        </Tag>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Row gutter={8}>
          <Col>
            <Tooltip title="Modifier">
              <Button 
                icon={<EditOutlined />} 
                onClick={() => {
                  setEditingAvailability(record);
                  setAvailabilityModalVisible(true);
                }}
              />
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title="Supprimer">
              <Button 
                danger 
                icon={<DeleteOutlined />} 
                onClick={() => deleteDisponibility(record)}
              />
            </Tooltip>
          </Col>
        </Row>
      )
    }
  ];

  // Render calendar cells
  const renderCelluleCalendrier = (value) => {
    const dateFormatee = value.format('YYYY-MM-DD');
    const disponibilite = availabilities.find(
      dispo => moment(dateFormatee).isBetween(dispo.dateDebut, dispo.dateFin, null, '[]')
    );
    
    return disponibilite ? (
      <Tooltip title={`${disponibilite.type}`}>
        <div 
          style={{ 
            backgroundColor: disponibilite.type === 'BLOQUE' ? '#ff4d4f' : 
                             disponibilite.type === 'JOURNEE_PARTIELLE' ? '#faad14' : 
                             '#52c41a', 
            color: 'white', 
            borderRadius: '4px',
            padding: '2px'
          }}
        >
          {disponibilite.type==='BLOQUE' ? <StopOutlined /> : disponibilite.type==='JOURNEE_PARTIELLE' ? <ClockCircleOutlined /> : <CheckCircleOutlined />}
        </div>
      </Tooltip>
    ) : null;
  };

  return (
    <>
      <NavHote />
      <div style={{ padding: 24, marginTop: "120px" }}>
        <Title level={2} style={{ textAlign: 'center', marginBottom: 24 }}>
          Tableau de Bord de l'Hôte
        </Title>
        <HostExperiences />
        <Row gutter={[16, 16]} style={{ marginBottom: 24 }}>
          {Object.entries(statistiques).map(([key, value]) => (
            <Col xs={24} sm={12} md={8} lg={6} key={key}>
              <Card>
                <Statistic 
                  title={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                  value={value} 
                />
              </Card>
            </Col>
          ))}
        </Row>

        <Divider orientation="center">Gestion des Disponibilités</Divider>

        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Card 
              title="Calendrier des Disponibilités" 
              extra={
                <Button 
                  type="primary" 
                  onClick={() => {
                    setEditingAvailability(null);
                    setAvailabilityModalVisible(true);
                  }}
                >
                  Ajouter une Disponibilité
                </Button>
              }
            >
              <Calendar 
                dateCellRender={renderCelluleCalendrier}
                fullscreen={false} 
              />
            </Card>
          </Col>

          <Col xs={24} md={12}>
            <Card title="Liste des Disponibilités">
              <Table 
                dataSource={availabilities} 
                columns={colonnesDisponibilites}
                locale={{
                  emptyText: 'Aucune disponibilité enregistrée'
                }}
                rowKey="id"
              />
            </Card>
          </Col>
        </Row>

        <Modal
          title={editingAvailability ? "Modifier la Disponibilité" : "Ajouter une Disponibilité"}
          visible={isAvailabilityModalVisible}
          onCancel={() => {
            setAvailabilityModalVisible(false);
            setEditingAvailability(null);
          }}
          footer={null}
        >
          <Form 
            onFinish={saveDisponibility}
            initialValues={
              editingAvailability 
                ? {
                    plageDate: [
                      moment(editingAvailability.dateDebut), 
                      moment(editingAvailability.dateFin)
                    ],
                    type: editingAvailability.type
                  } 
                : {}
            }
          >
            <Form.Item 
              name="plageDate" 
              label="Plage de Dates" 
              rules={[{ required: true, message: 'Veuillez sélectionner une plage de dates' }]}
            >
              <RangePicker 
                style={{ width: '100%' }} 
                format="DD/MM/YYYY"
                locale="fr"
              />
            </Form.Item>
            <Form.Item 
              name="type" 
              label="Type de Disponibilité" 
              rules={[{ required: true, message: 'Veuillez sélectionner un type' }]}
            >
              <Select placeholder="Sélectionnez un type">
                <Option value="JOURNEE_PARTIELLE">Partielle</Option>
                <Option value="BLOQUE">Bloqué</Option>
                <Option value="DISPONIBLE">Disponible</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {editingAvailability ? 'Modifier' : 'Ajouter'}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        
        <ExperienceAvailability />
        
      </div>
    </>
  );
}

export default TableauDeBordHote;
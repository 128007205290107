import React from "react";
import "./Footer.css";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import logo from "../img/FooterLogo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="logo">
          <center>
            {" "}
            <img src={logo} alt="Feast and Food" />{" "}
          </center>
        </div>
        <nav className="footer-nav">
          <ul>
            <li>
              <Link to="/">Accueil</Link>
            </li>
            <li>
              <a href="#">Expériences</a>
            </li>
            <li>
              <a href="#">À propos</a>
            </li>
            <li>
              <a href="#">Contact</a>
            </li>
          </ul>
        </nav>
        <div className="footer-text">
          <p>
            Welcome to Feast & Food, where every dish is a journey, and <br />
            every bite tells a story 🍽️
          </p>
        </div>
        <div className="social-icons">
          <a href="#">
            <FaFacebook />
          </a>
          <a href="#">
            <FaTwitter />
          </a>
          <a href="#">
            <FaLinkedin />
          </a>
          <a href="#">
            <FaInstagram />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

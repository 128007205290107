import React, { useEffect, useState } from "react";
//import avatar from "../img/fi_user.webp";
import uploadImg from "../img/fi_refresh-ccw.webp";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import NavHote from "./NavHote";
import { useNavigate } from "react-router-dom";
import {message} from "antd";
import { Tag } from "antd";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import VerificationDialog from "./VerificationDialog";
import PreviewDialog from "./PreviewDialog";
const schema = yup.object().shape({
  nom: yup.string().required("Nom est requis"),
  prenom: yup.string().required("Prénom est requis"),
  dateDeNaissance: yup.date().required("Date de naissance est requise"),
  ville: yup.string().required("Ville est requise"),
  numeroDeTelephone: yup
    .string()
    .matches(/^(?:\+212|0)\d{9}$/, "Numéro de téléphone invalide")
    .required("Numéro de téléphone est requis"),
  bio: yup.string().required("Bio est requise"),
  etudes: yup.string().required("Où j’ai étudié ? est requis"),
  travail: yup.string().required("Mon travail est requis"),
  anecdote: yup.string().required("Une anecdote sur moi est requise"),
  chanson: yup.string().required("Ma chanson préférée est requise"),
  decennie: yup.string().required("Ma décennie de naissance est requise"),
  talent: yup.string().required("Mon talent le plus insolite est requis"),
  passion: yup.string().required("Ce qui me passionne est requis"),
  biographie: yup.string().required("Le titre de ma biographie est requis"),
  langue: yup.string().required("Je parle est requis"),
  residence: yup.string().required("Où j’habite est requis"),
});

function Profil() {
  // "../img/fi_user.webp"
  const [avatarImage, setavatarImage] = useState("avatar");
  // initial state with the default avatar
  const [avatarImageError, setavatarImageError] = useState("");
  const [file,setFile]=useState(null);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  if(!keycloak.authenticated){
    console.log("Authenticated");
    navigate("/HostProfile");
  }
  const handleFileChange = (event) => {
    
    const file = event.target.files[0];
    setFile(file);
    if (file) {
      // Check if file is an image
      if (file.type.startsWith("image/")) {
        // Check file size (must be less than 2MB)
        if (file.size <= 2 * 1024 * 1024) {
          const reader = new FileReader();

          reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;

            img.onload = () => {
              const { width, height } = img;

              // Check image dimensions (must be at least 150x150 pixels)
              if (width < 150 || height < 150) {
                setavatarImageError(
                  "Image dimensions must be at least 150x150 pixels"
                );
              }
              // Check aspect ratio (must be square for profile images)
               else {
                setavatarImage(reader.result); // Set the image URL for display
                setavatarImageError(""); // Clear any previous errors
              }
            };

            img.onerror = () => {
              setavatarImageError("Error loading image");
            };
          };

          reader.readAsDataURL(file);
        } else {
          setavatarImageError("File size must be less than 2MB");
        }
      } else {
        setavatarImageError("Please upload a valid image file");
      }
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if(file){
    try {
      const formData = new FormData();
  
      // Append the file to the formData object
      if (file) {
        formData.append("file", file);
      }
      else{
        formData.append("file", avatarImage);
      }
      // Append the serialized HostProfileDto data as a string
      formData.append("profilDTO", JSON.stringify(data));

      console.log("formData", formData);
      // Make the API call with multipart/form-data
      const response = await axios.put(
        `${process.env.REACT_APP_API_SER}host/profile/updateProfileImg`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      console.log("Profile updated successfully", response.data);
      localStorage.setItem("avatar", avatarImage);
      alert("Votre compte a été mis à jour avec succès et sera examiné par nos équipes pour validation.");
      //refrecsh the page
      window.location.reload();
      // You might want to add some user feedback here, like a success message
    } catch (error) {
      
      console.error("Error updating profile:", error);
      alert("Error updating profile");
      // You might want to add some user feedback here, like an error message
    }}
    else{
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_SER}host/profile/updateProfile`,
          data, // The form data to be sent in the request body
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`, // JWT for authorization
              "Content-Type": "application/json", // Optional: specifying content type
            },
          }
        );
  
        // Handle the successful response (e.g., display a success message)
        console.log("Profile updated successfully", response.data);
        alert("Votre compte a été mis à jour avec succès et sera examiné par nos équipes pour validation.");
      } catch (error) {
        // Handle the error (e.g., display an error message)
        console.error("Error updating profile:", error);
      }
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_SER+"host/profile/getProfile",
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
            },
          }
        );
        console.log("Profile data:", response.data);
        setIsEnabled(response.data.isEnabled);
        console.log("is enabled : ?????",response.data.isEnabled);
        setavatarImage(process.env.REACT_APP_API_SER+"photos/"+ response.data.avatar); // Update the profile picture
        console.log("avatarImage",avatarImage);
        localStorage.setItem("avatar", avatarImage);
        reset(response.data); // Reset form with received data
        if(!response.data.isEnabled){
        message.warning("Votre compte est en cours de validation par nos équipes.");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchProfile(); // Call the async function
  }, [keycloak.token, reset]);
  

  const handleClose = () => {
    setDialogOpen(false);
  };
  return (
    <><NavHote avatar={avatarImage}></NavHote>
     
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="px-8 pt-8 md:py-16 md:px-60 bg-gray-50">
      <h2 className="md:text-xl poppins-regular mb-6">
        Information Personnelles
      </h2>

      <div className="flex justify-around">
        <div>
          <div className="relative w-[110px] h-[110px] flex justify-center items-center md:w-[115px] md:h-[115px] bg-ellipse-background rounded-full">
            <img
              src={avatarImage} // Replace with actual profile picture URL or component
              alt="Profile"
              className="md:w-[100%] md:h-auto rounded-full"
              
            />
            
            <div className="absolute flex justify-center items-center top-[75%] left-[85%] w-[25px] h-[25px] bg-black rounded-full">
              <input
                type="file"
                accept="image/*"
                className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                aria-label="Upload Image"
                onChange={handleFileChange}
              />
              <img
                src={avatarImage ? uploadImg : uploadImg}
                alt="Upload"
                className="w-[18px] h-[18px] pointer-events-none"
              />
            </div>
            
          </div>
          {avatarImageError && (
            <p className="text-red-500 mt-2">{avatarImageError}</p>
          )}{" "}
          {/* Display error messages */}
          <center>
          <Tag color={isEnabled ? 'success' : 'error'}>
              {isEnabled ? 'Active' : 'Disabled'}
          </Tag>
          </center>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8 md:w-3/4">
          <div>
            <input
              {...register("nom")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Nom"
            />
            {errors.nom && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.nom.message}
              </p>
            )}
          </div>
          <div>
            <input
              {...register("prenom")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Prénom"
            />
            {errors.prenom && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.prenom.message}
              </p>
            )}
          </div>

          <div>
            <input
              {...register("dateDeNaissance")}
              type="date"
              className="mt-1 block placeholder:text-gray-text text-gray-text w-full px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
            />
            {errors.dateDeNaissance && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.dateDeNaissance.message}
              </p>
            )}
          </div>

          <div>
            <input
              {...register("ville")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Ville"
            />
            {errors.ville && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.ville.message}
              </p>
            )}
          </div>

          <div className="md:col-span-2">
            <input
              {...register("numeroDeTelephone")}
              type="tel"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Numéro de téléphone"
            />
            {errors.numeroDeTelephone && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.numeroDeTelephone.message}
              </p>
            )}
          </div>

          <div className="md:col-span-2">
            <textarea
              {...register("bio")}
              className="mt-1 block w-full text-gray-text placeholder:text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              rows="3"
              placeholder="Votre bio"></textarea>
            {errors.bio && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.bio.message}
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Second Part: Flex Column Layout */}
      <div className="flex flex-col gap-4 mt-8">
        <h2 className="md:text-xl poppins-regular mb-6">À propos de vous</h2>
        <div className="flex flex-col gap-4 px-12">
          <div>
            <input
              {...register("etudes")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Où j'ai étudié ?"
            />
            {errors.etudes && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.etudes.message}
              </p>
            )}
          </div>

          <div>
            <input
              {...register("travail")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Mon travail"
            />
            {errors.travail && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.travail.message}
              </p>
            )}
          </div>

          <div>
            <input
              {...register("anecdote")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Une anecdote sur moi"
            />
            {errors.anecdote && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.anecdote.message}
              </p>
            )}
          </div>

          <div>
            <input
              {...register("chanson")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Ma chanson préférée"
            />
            {errors.chanson && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.chanson.message}
              </p>
            )}
          </div>

          <div>
            <input
              {...register("decennie")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Ma décennie de naissance"
            />
            {errors.decennie && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.decennie.message}
              </p>
            )}
          </div>

          <div>
            <input
              {...register("talent")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Mon talent le plus insolite"
            />
            {errors.talent && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.talent.message}
              </p>
            )}
          </div>

          <div>
            <input
              {...register("passion")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Ce qui me passionne"
            />
            {errors.passion && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.passion.message}
              </p>
            )}
          </div>

          <div>
            <input
              {...register("biographie")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Le titre de ma biographie"
            />
            {errors.biographie && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.biographie.message}
              </p>
            )}
          </div>

          <div>
            <input
              {...register("langue")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Je parle"
            />
            {errors.langue && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.langue.message}
              </p>
            )}
          </div>

          <div>
            <input
              {...register("residence")}
              type="text"
              className="mt-1 block w-full placeholder:text-gray-text text-gray-text px-5 py-5 border border-gray-input rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 poppins-regular text-lg"
              placeholder="Où j'habite"
            />
            {errors.residence && (
              <p className="poppins-regular   px-4 py-3 text-red-700">
                {errors.residence.message}
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div className="flex justify-end m-8 pr-4">
        <button
          className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded poppins-regular"
          type="submit">
          Save
        </button>
      </div>
    </form>
    
    </>
  );
}

export default Profil;

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
  Box,
  Grid,
  Avatar,
} from "@mui/material";

function PreviewDialog({ open, onClose, onConfirm, avatarImage, previewData }) {
  const hostData = previewData;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#1976d2" }}>
          Confirmer les modifications
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: "20px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4} md={3}>
            <Avatar
              src={avatarImage}
              alt={`${hostData.prenom} ${hostData.nom}`}
              sx={{ width: 150, height: 150, margin: "auto", border: "3px solid #1976d2" }}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            <Typography variant="h5" gutterBottom>
              {hostData.titreBiographique}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {hostData.bio}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginY: "20px" }} />
        <Box sx={{ backgroundColor: "#f9f9f9", padding: "15px", borderRadius: "10px" }}>
          <Typography variant="body1">
            <strong>Nom:</strong> {hostData.nom}
          </Typography>
          <Typography variant="body1">
            <strong>Prénom:</strong> {hostData.prenom}
          </Typography>
          <Typography variant="body1">
            <strong>Email:</strong> {hostData.email}
          </Typography>
          <Typography variant="body1">
            <strong>Numéro de téléphone:</strong> {hostData.numTele}
          </Typography>
          <Typography variant="body1">
            <strong>Ville:</strong> {hostData.city}
          </Typography>
          <Typography variant="body1">
            <strong>Langues parlées:</strong> {hostData.langues}
          </Typography>
          <Typography variant="body1">
            <strong>Travail:</strong> {hostData.travail}
          </Typography>
          <Typography variant="body1">
            <strong>Passions:</strong> {hostData.passions}
          </Typography>
          <Typography variant="body1">
            <strong>Anecdote:</strong> {hostData.anecdote}
          </Typography>
          <Typography variant="body1">
            <strong>Description du lieu:</strong> {hostData.descriptionLieu}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "10px 20px" }}>
        <Button onClick={onClose} color="secondary" variant="outlined" sx={{ marginRight: "8px" }}>
          Annuler
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreviewDialog;

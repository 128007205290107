import React, { useEffect, useRef, useState } from "react";
import "ol/ol.css";
import { Map, View } from "ol";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM } from "ol/source";
import { Vector as VectorSource } from "ol/source";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { Circle as CircleGeom } from "ol/geom";
import { Feature } from "ol";
import axios from "axios";
import { fromLonLat } from "ol/proj";
import "./HostMapWithRadius.css";
import { useParams } from "react-router-dom";


const HostMapWithRadius = ({ id }) => {
  const [position, setPosition] = useState(null);
  const mapRef = useRef();
  const circleRef = useRef();

  useEffect(() => {
    const fetchHostLocation = async () => {
      try {
       

        const response = await axios.get(process.env.REACT_APP_API_SER+`experience/localisation/${id}`);

        const localisation = response.data;
        const [lat, lng] = localisation.split(",").map(Number);
        setPosition({ lat, lng });
      } catch (error) {
        console.error("Error fetching host location:", error);
      }
    };

    fetchHostLocation();
  }, [id]);

  useEffect(() => {
    if (position) {
      const map = new Map({
        target: mapRef.current,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          center: fromLonLat([position.lng, position.lat]),
          zoom: 14.5,
          maxZoom: 15,
        }),
      });

      const circleFeature = new Feature({
        geometry: new CircleGeom(fromLonLat([position.lng, position.lat]), 700),
      });

      circleFeature.setStyle(
        new Style({
          stroke: new Stroke({
            color: "blue",
            width: 2,
          }),
          fill: new Fill({
            color: "rgba(48, 48, 255, 0.5)",
          }),
        })
      );

      const vectorSource = new VectorSource({
        features: [circleFeature],
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });

      map.addLayer(vectorLayer);
      circleRef.current = circleFeature;

      map.getView().on("change:resolution", () => {
        const zoom = map.getView().getZoom();
        const newRadius = 800 * Math.pow(1.6, 14.5 - zoom);
        circleFeature.getGeometry().setRadius(newRadius);
      });

      return () => {
        map.setTarget(null);
      };
    }
  }, [position]);

  return (
    <div>
      <h1>Le lieu</h1>
      <h2>
        L'adresse complète sera communiquée une fois la réservation confirmée
      </h2>
      <div id="host-map-radius" ref={mapRef} className="map-container1"></div>
    </div>
  );
};

export default HostMapWithRadius;

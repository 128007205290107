import React, { useState } from "react";
import NavHote from "./NavHote";
import Profil from "./Profil";
import Footer from "../components/Footer";
function ProfileHost() {
  const [activeTab, setActiveTab] = useState("Profil");
  console.log(localStorage.getItem("avatar"));
  return (
    <div>
      
      
      <div className="px-8 sm:pt-[90px] bg-white mt-[150px] flex flex-col gap-4 md:pt-24 md:pl-40 md:gap-12">
        <div className="md:text-xl leading-8 poppins-medium"> Paramètres</div>
        <div className="flex  space-x-12 md:text-xl  poppins-regular text-custom-gray border-b-2 border-gray-line">
          <button
            className={`relative  pb-4 text-lg font-medium group ${
              activeTab === "Profil" ? "text-button-hover" : "text-custom-gray"
            } hover:text-button-hover `}
            onClick={() => {
              setActiveTab("Profil");
            }}>
            Profil
            <span
              className={`absolute  left-0 bottom-0 w-full h-0.5   ${
                activeTab === "Profil" ? "bg-button-hover" : "bg-white"
              } group-hover:bg-button-hover`}></span>
          </button>
          <button
            className={`relative  pb-4 text-lg font-medium group ${
              activeTab === "Permissions"
                ? "text-button-hover"
                : "text-custom-gray"
            } hover:text-button-hover `}
            onClick={() => {
              setActiveTab("Permissions");
            }}>
            Permissions
            <span
              className={`absolute  left-0 bottom-0 w-full h-0.5   ${
                activeTab === "Permissions" ? "bg-button-hover" : "bg-white"
              } group-hover:bg-button-hover`}></span>
          </button>
          <button
            className={`relative  pb-4 text-lg font-medium group ${
              activeTab === "Confidentialité"
                ? "text-button-hover"
                : "text-custom-gray"
            } hover:text-button-hover `}
            onClick={() => {
              setActiveTab("Confidentialité");
            }}>
            Confidentialité
            <span
              className={`absolute  left-0 bottom-0 w-full h-0.5   ${
                activeTab === "Confidentialité" ? "bg-button-hover" : "bg-white"
              } group-hover:bg-button-hover`}></span>
          </button>
          <button
            className={`relative  pb-4 text-lg font-medium group ${
              activeTab === "Parrainage"
                ? "text-button-hover"
                : "text-custom-gray"
            } hover:text-button-hover `}
            onClick={() => {
              setActiveTab("Parrainage");
            }}>
            Parrainer un ami
            <span
              className={`absolute  left-0 bottom-0 w-full h-0.5   ${
                activeTab === "Parrainage" ? "bg-button-hover" : "bg-white"
              } group-hover:bg-button-hover`}></span>
          </button>
        </div>
      </div>

      {activeTab === "Profil" && <Profil></Profil>}
      <Footer></Footer>
    </div>
  );
}

export default ProfileHost;

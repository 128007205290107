import React, { useState, useEffect } from "react";
import Nav from "./comps/Nav";
import cursorLogo from "./img/cursor-pointer.webp";
import city1 from "./img/Ellipse 18.webp";
import city2 from "./img/Ellipse 19(1).webp";
import city3 from "./img/Ellipse 20(1).webp";
import phone from "./img/X Mockup.webp";
import HostForm from "./comps/HostForm";
import FAQ from "./comps/Faq";
import Footer from "./comps/Footer";
import HostClient from "./comps/HostClient";
function InscriptionClient() {
  const handleScroll = () => {
    const targetSection = document.getElementById("target-section");
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex flex-col w-full relative overflow-x-hidden">
      <Nav Color={"white"} />
      <>
        {/* Style pour les écrans larges */}
        {!isMobile && (
          <div
            className="relative flex items-center justify-center bg-cover bg-no-repeat p-4 z-10 sm:p-8 md:p-12 text-center"
            style={{ minHeight: "900px",
            backgroundImage: "url('/imgs/clientbg.png')",
          }}
          >
            <div className="absolute flex flex-col items-center justify-center text-center text-white gap-y-8 h-full w-full">
              <h2
                className="mb-4 text-3xl md:text-4xl lg:text-5xl xl:text-6xl poppins-medium w-full whitespace-nowrap"
                style={{ marginLeft: "-8px" }}
              >
                Partagez et vivez votre passion
              </h2>

              <button
                type="button"
                onClick={handleScroll}
                className="flex items-center justify-center w-full max-w-[90%] md:max-w-[500px] h-16 md:h-[77px] rounded-full px-4 py-2 poppins-regular text-lg md:text-2xl border-2 bg-white text-orange-text transition-all duration-200"
              >
                Inscrivez-vous pour devenir client
                <img
                  src={cursorLogo}
                  alt="cursor"
                  className="ml-2 w-6 h-6 md:w-8 md:h-8"
                />
              </button>
            </div>
          </div>
        )}
        {isMobile && (
          <div
            style={{
              backgroundImage:
                "linear-gradient(0.64deg, rgba(0, 0, 0, 0.175) 14.53%, rgba(0, 0, 0, 0.35) 47.23%),url('/imgs/clientbg.png')",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "500px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "4px",
              textAlign: "center",
              zIndex: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
                gap: "32px",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "64px",
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: "5.5vw",
                  lineHeight: "1.35",
                  fontWeight: 500,
                  color: "white",
                  whiteSpace: "nowrap",
                  fontFamily: "'Poppins', sans-serif",
                  display: "block",
                  margin: "0 auto",
                }}
              >
                Partagez et vivez votre passion
              </span>

              <div style={{ marginTop: "-10px" }}>
                <button
                  type="button"
                  onClick={handleScroll}
                  style={{
                    width: "280px",
                    height: "42.85px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    opacity: 1,
                    borderRadius: "9999px",
                    padding: "0.8rem 0.8rem",
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "13px",
                    border: "0.5px solid",
                    backgroundColor: "white",
                    color: "#d7a022",
                    transition: "all 0.2s",
                  }}
                >
                  Inscrivez-vous pour devenir hôte
                  <img
                    src={cursorLogo}
                    alt="cursor"
                    style={{
                      marginLeft: "4px",
                      width: "30px",
                      height: "30px",
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        )}
      </>

      <>
        {/* Style pour les écrans larges */}
        {!isMobile && (
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              backgroundImage: "url('/imgs/Maroc.png')",
              backgroundSize: "100% auto",
              backgroundPosition: "right",
              backgroundRepeat: "no-repeat",
              height: "900px",
              width: "100%",
            }}
          >
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
                position: "absolute",
                top: "120px",
                left: "70px",
                whiteSpace: "nowrap",
              }}
            >
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "36px",
                  fontWeight: "600",
                }}
              >
                Avec Feast and Food,
              </span>
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "32px",
                  fontWeight: "400",
                }}
              >
                c'est facile de se lancer et accueillir <br /> tout le monde
                pour{" "}
                <span
                  style={{
                    color: "#C1272D",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                  }}
                >
                  une expérience <br /> 100% marocaine
                </span>
              </span>
            </p>

            <img
              src={phone}
              alt="phone"
              style={{
                position: "absolute",
                width: "400px",
                top: "300px",
                left: "60%",
              }}
            />
          </div>
        )}

        {/* Style pour les écrans mobiles */}
        {isMobile && (
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              backgroundColor: "#C1272D",
              backgroundImage: "url('/img/Maroc.png')",
              backgroundSize: "130%",
              backgroundPosition: "left center",
              backgroundRepeat: "no-repeat",
              height: "670px",
              marginTop: "-145px",
              marginLeft: "0px",
              width: "100%",
            }}
          >
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
                position: "absolute",
                top: "200px",
                left: "40px",
              }}
            >
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                Avec Feast and Food,
              </span>
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: "400",
                }}
              >
                c'est facile de se lancer et accueillir <br /> tout le monde
                pour{" "}
                <span
                  style={{
                    color: "#C1272D",
                    fontFamily: "Poppins",
                    fontWeight: "500",
                  }}
                >
                  une expérience <br /> 100% marocaine
                </span>
              </span>
            </p>

            <img
              src={phone}
              alt="phone"
              style={{
                position: "absolute",
                width: "140px",
                top: "460px",
                left: "55%",
              }}
            />
          </div>
        )}
      </>
      <div
        id="target-section"
        className="flex flex-col bg-white px-4 py-3  sm:px-[100px] md:px-[150px] md:py-6 lg:px-[200px] lg:py-8"
      >
        <div className="flex flex-col gap-2 m-2 md:gap-3 md:m-4">
          <div className="poppins-semibold text-sm md:text-lg lg:text-4xl">
            Inscrivez-vous dès maintenant
          </div>
          <div className="poppins-regular text-xs md:text-sm lg:text-base text-gray-text">
            Remplissez tous les champs*
          </div>
        </div>

        <div className="h-[500px] bg-white">
          <HostClient />
        </div>
      </div>
      <div className="w-full mt-[300px] lg:mt-[50px]">
        <FAQ />

        <Footer />
      </div>
    </div>
  );
}

export default InscriptionClient;

import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export default function PrivateRoute({ children, roles }) {
  const { keycloak, initialized } = useKeycloak();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (initialized) {
      const checkAuthorization = () => {
        if (!keycloak.authenticated) {
          return false;
        }

        if (!roles || roles.length === 0) {
          return true;
        }

        return roles.some(
          (role) =>
            keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role)
        );
      };

      setIsAuthorized(checkAuthorization());
      setIsLoading(false);
    }
  }, [keycloak, initialized, roles]);

  if (isLoading) {
    // Optionally, you can render a loading spinner or placeholder here
    return <div>Loading...</div>;
  }

  if (!isAuthorized) {
    return <Navigate to="/" />;
  }

  return children;
}

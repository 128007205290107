import * as React from 'react';
import { Grid, Typography, Button, Card, CardContent, CardMedia, Badge, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useMediaQuery, useTheme } from '@mui/material';
import Nav from './components/Annonce/Nav';

// Assuming these are the custom colors for your app based on the screenshot
const colors = {
    primary: '#ff5722',    // Orange-red for primary elements
    secondary: '#4CAF50',  // Green used for some icons or secondary text
    discount: '#E91E63',   // Pinkish color for discount badges
    textPrimary: '#333',   // Dark text for titles
    textSecondary: '#757575',  // Gray text for secondary information
};

export function AllExperiences() {
    const [experiences, setExperiences] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // For mobile screen size detection

    React.useEffect(() => {
        const fetchExperiences = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_SER + 'experience/0/120');
                setExperiences(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching experiences:', error);
                setLoading(false);
            }
        };
        fetchExperiences();
    }, []);

    return (
        <>
            <Nav color="light" />
            <Box sx={{ padding: 2, backgroundColor: '#f9f9f9' }} marginTop={isMobile ? "20px" : "100px"}>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={3}>
                        {experiences.map((e) => (
                            <Grid item xs={12} sm={6} md={4} key={e.id}>
                                <Card sx={{ position: 'relative', borderRadius: '12px', boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)' }}>
                                    {/* Discount Badge */}
                                    {e.discount && (
                                        <Badge
                                            badgeContent={<Typography sx={{ backgroundColor: colors.discount, padding: '4px', borderRadius: '4px', color: '#fff' }}>{e.discount}%</Typography>}
                                            overlap="rectangular"
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            sx={{ position: 'absolute', top: '10px', left: '10px' }}
                                        />
                                    )}

                                    {/* Image with consistent size and object fit */}
                                    <CardMedia
                                        component="img"
                                        image={`${process.env.REACT_APP_API_SER}photos/${e.photos[0]?.id}`}
                                        alt={e.titre}
                                        sx={{
                                            width: '300px',        // Ensures the image fills the container width
                                            height: '300px',      // Fixed height for all images
                                            objectFit: 'cover',   // Ensures aspect ratio is maintained and image is cropped if necessary
                                            borderTopLeftRadius: '12px',
                                            borderTopRightRadius: '12px',
                                        }}
                                    />

                                    {/* Card Content */}
                                    <CardContent sx={{ padding: '16px', textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.textPrimary }}>
                                            {e.titre}
                                        </Typography>
                                        <Typography variant="body2" color={colors.textSecondary} sx={{ marginBottom: 2 }}>
                                            {e.description}
                                        </Typography>

                                        {/* Price and Discount */}
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
                                            <Typography variant="body1" sx={{ color: colors.primary, fontWeight: 'bold' }}>
                                                {e.price} MAD
                                            </Typography>
                                            {e.originalPrice && (
                                                <Typography variant="body2" color={colors.textSecondary} sx={{ textDecoration: 'line-through' }}>
                                                    {e.originalPrice} MAD
                                                </Typography>
                                            )}
                                        </Box>

                                        {/* Ratings and Button */}
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography variant="body2" sx={{ color: colors.secondary }}>
                                                {e.rating} ★ ({e.reviews} reviews)
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                sx={{
                                                    backgroundColor: colors.primary,
                                                    color: '#fff',
                                                    textTransform: 'none',
                                                    '&:hover': {
                                                        backgroundColor: '#e64a19',  // Darker orange on hover
                                                    },
                                                }}
                                            >
                                                Voir plus
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
        </>
    );
}

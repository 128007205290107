import React from 'react';
import { Row, Col, Card, Statistic, Progress, Divider } from 'antd';
import { UserOutlined, DollarOutlined, ShopOutlined, LineChartOutlined } from '@ant-design/icons';
import { Area, Bar, Pie } from '@ant-design/plots';

const AdminDashboard = () => {
  // Mock Data
  const userStats = 1200;
  const revenue = 48000;
  const bookings = 870;
  const cancellationRate = 5;

  const areaData = [
    { month: 'January', value: 3000 },
    { month: 'February', value: 4500 },
    { month: 'March', value: 5000 },
    { month: 'April', value: 7000 },
    { month: 'May', value: 8000 },
    { month: 'June', value: 6500 },
  ];

  const barData = [
    { category: 'Cuisine', value: 300 },
    { category: 'Ambiance', value: 250 },
    { category: 'Service', value: 150 },
    { category: 'Cleanliness', value: 200 },
  ];

  const pieData = [
    { type: 'Completed', value: 700 },
    { type: 'Cancelled', value: 50 },
    { type: 'Pending', value: 120 },
  ];

  return (
    <div style={{ padding: 24, background: '#f5f5f5', minHeight: '100vh' }}>
      <Row gutter={[16, 16]}>
        {/* Global Statistics */}
        <Col span={6}>
          <Card>
            <Statistic 
              title="Total Users" 
              value={userStats} 
              prefix={<UserOutlined />} 
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic 
              title="Total Revenue (MAD)" 
              value={revenue} 
              prefix={<DollarOutlined />} 
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic 
              title="Total Bookings" 
              value={bookings} 
              prefix={<ShopOutlined />} 
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic 
              title="Cancellation Rate (%)" 
              value={cancellationRate} 
              precision={2} 
              suffix="%" 
              valueStyle={{ color: '#cf1322' }}
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/* Charts Section */}
      <Row gutter={[16, 16]}>
        {/* Revenue Trends */}
        <Col span={12}>
          <Card title="Monthly Revenue">
            <Area
              data={areaData}
              xField="month"
              yField="value"
              color="#5B8FF9"
              height={250}
              smooth
              point={{
                size: 5,
                shape: 'circle',
                style: { fill: 'white', stroke: '#5B8FF9', lineWidth: 2 },
              }}
            />
          </Card>
        </Col>

        {/* Category Bookings */}
        <Col span={12}>
          <Card title="Category-wise Bookings">
            <Bar
              data={barData}
              xField="category"
              yField="value"
              color="#5AD8A6"
              barWidthRatio={0.4}
              height={250}
              xAxis={{ title: { text: 'Category' } }}
              yAxis={{ title: { text: 'Bookings' } }}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
        {/* Booking Status Distribution */}
        <Col span={12}>
          <Card title="Booking Status">
            <Pie
              data={pieData}
              angleField="value"
              colorField="type"
              radius={0.8}
              label={{
                type: 'outer',
                content: '{name} {percentage}',
              }}
              interactions={[{ type: 'element-active' }]}
              height={250}
            />
          </Card>
        </Col>

        {/* Booking Completion Rate */}
        <Col span={12}>
          <Card title="Booking Completion Rate">
            <Progress
              type="dashboard"
              percent={(pieData.find((d) => d.type === 'Completed')?.value / bookings) * 100}
              format={(percent) => `${percent?.toFixed(2)}%`}
              strokeColor="#3f8600"
              gapDegree={75}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboard;

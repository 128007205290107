import React, { useEffect, useState } from "react";
import { 
  Form, 
  Input, 
  InputNumber, 
  DatePicker, 
  TimePicker, 
  Select, 
  Button, 
  Upload, 
  Typography, 
  Card, 
  Row, 
  Col, 
  message, 
  Tooltip, 
  Space,
  Divider,
  Switch,
  Rate,
  Avatar,
  Steps,
  Tag,
  Tabs,
  Descriptions
} from "antd";
import { 
  UploadOutlined, 
  SaveOutlined, 
  CloseOutlined, 
  InfoCircleOutlined,
  UserOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  ClockCircleOutlined,
  CameraOutlined,
  CheckCircleOutlined,
  GlobalOutlined,
  PlusOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

const { Option } = Select;
const { TextArea } = Input;
const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

// Helper function for file preview
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const EditExperience = () => {
  const [form] = Form.useForm();
  const [experience, setExperience] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const navigate = useNavigate();
  const e = location.state || JSON.parse(localStorage.getItem("eData"));

  useEffect(() => {
    if (!e) {
      message.error("No experience data found");
      navigate("/");
      return;
    }

    setExperience(e);
    form.setFieldsValue({
      ...e,
      temps: e.temps ? dayjs(e.temps) : null,
      heurDebut: e.heurDebut ? dayjs(e.heurDebut, 'HH:mm') : null,
      heurFin: e.heurFin ? dayjs(e.heurFin, 'HH:mm') : null,
    });

    if (e.photos) {
      setFileList(e.photos.map(photo => ({
        uid: photo.id,
        name: `Photo ${photo.id}`,
        status: 'done',
        url: `${process.env.REACT_APP_API_SER}photos/${photo.id}`,
      })));
    }
  }, [e, navigate, form]);

  const handleFinish = async (values) => {
    try {
      setLoading(true);
      const formattedValues = {
        ...values,
        temps: values.temps?.format("YYYY-MM-DD"),
        heurDebut: values.heurDebut?.format("HH:mm"),
        heurFin: values.heurFin?.format("HH:mm"),
        photos: fileList.map(file => ({
          id: file.uid,
          filePath: file.url || file.response?.url
        })),
        host: experience.host,
        id: experience.id,
        isVerified: experience.isVerified
      };

      // API call would go here
      console.log("Submitting:", formattedValues);
      
      message.success("Experience updated successfully!");
      navigate(-1);
    } catch (error) {
      message.error("Failed to update experience");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const steps = [
    {
      title: 'Basic Info',
      content: 'basicInfo',
      icon: <InfoCircleOutlined />
    },
    {
      title: 'Details',
      content: 'details',
      icon: <ClockCircleOutlined />
    },
    {
      title: 'Photos',
      content: 'photos',
      icon: <CameraOutlined />
    },
    {
      title: 'Review',
      content: 'review',
      icon: <CheckCircleOutlined />
    }
  ];

  const uploadProps = {
    multiple: true,
    listType: "picture-card",
    fileList,
    onChange: ({ fileList: newFileList }) => {
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        message.error(`${file.name} is not an image file`);
      }
      return isImage || Upload.LIST_IGNORE;
    },
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      window.open(file.url || file.preview, '_blank');
    }
  };

  if (!experience) {
    return <div>Loading...</div>;
  }

  const renderBasicInfo = () => (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Title"
            name="titre"
            rules={[{ required: true, message: "Please enter a title" }]}
          >
            <Input 
              placeholder="Enter experience title"
              suffix={
                <Tooltip title="The main title of your experience">
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="City"
            name="lieu"
            rules={[{ required: true, message: "Please enter the city" }]}
          >
            <Input 
              prefix={<EnvironmentOutlined />}
              placeholder="Enter city name"
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: "Please enter a description" }]}
      >
        <TextArea 
          rows={4} 
          placeholder="Enter experience description" 
          showCount 
          maxLength={1000} 
        />
      </Form.Item>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Price Per Guest (MAD)"
            name="prixParInvite"
            rules={[{ required: true, message: "Please enter the price" }]}
          >
            <InputNumber
              min={0}
              style={{ width: "100%" }}
              formatter={value => `${value} MAD`}
              parser={value => value.replace(' MAD', '')}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Maximum Guests"
            name="nombreMaxInvite"
            rules={[{ required: true, message: "Please enter max guests" }]}
          >
            <InputNumber min={1} max={20} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Cuisine Type"
            name="typeCuisine"
            rules={[{ required: true, message: "Please select cuisine type" }]}
          >
            <Select>
              <Option value="Halal">Halal</Option>
              <Option value="Vegetarian">Vegetarian</Option>
              <Option value="Vegan">Vegan</Option>
              <Option value="Traditional">Traditional</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Date"
            name="temps"
            rules={[{ required: true, message: "Please select date" }]}
          >
            <DatePicker 
              style={{ width: "100%" }}
              disabledDate={current => current && current < dayjs().startOf('day')}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Start Time"
            name="heurDebut"
            rules={[{ required: true, message: "Please select start time" }]}
          >
            <TimePicker format="HH:mm" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="End Time"
            name="heurFin"
            rules={[{ required: true, message: "Please select end time" }]}
          >
            <TimePicker format="HH:mm" style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Location"
        name="localisation"
        rules={[{ required: true, message: "Please enter location" }]}
      >
        <Input
          prefix={<EnvironmentOutlined />}
          placeholder="Enter coordinates (e.g., 34.0219907,-6.8376160)"
        />
      </Form.Item>
    </>
  );

  const renderDetails = () => (
    <>
      <Form.Item
        label="Inspiration"
        name="inspiration"
        rules={[{ required: true, message: "Please share your inspiration" }]}
      >
        <TextArea
          rows={4}
          placeholder="What inspired this experience?"
          showCount
          maxLength={500}
        />
      </Form.Item>

      <Form.Item
        label="Experience Duration"
        name="dureeExperience"
        rules={[{ required: true, message: "Please specify duration" }]}
      >
        <Input placeholder="How long will the experience last?" />
      </Form.Item>

      <Form.Item
        label="Interaction"
        name="intercation"
        rules={[{ required: true, message: "Please describe interaction" }]}
      >
        <TextArea
          rows={4}
          placeholder="How will you interact with guests?"
          showCount
          maxLength={500}
        />
      </Form.Item>

      <Form.Item
        label="Precautions"
        name="precautions"
        rules={[{ required: true, message: "Please list precautions" }]}
      >
        <TextArea
          rows={4}
          placeholder="Any safety precautions or requirements?"
          showCount
          maxLength={500}
        />
      </Form.Item>

      <Form.Item
        label="Booking Information"
        name="blocReserver"
        rules={[{ required: true, message: "Please provide booking info" }]}
      >
        <TextArea
          rows={4}
          placeholder="Important information for booking"
          showCount
          maxLength={500}
        />
      </Form.Item>
    </>
  );

  const renderPhotos = () => (
    <>
      <Form.Item
        label={
          <Space>
            <CameraOutlined />
            <span>Experience Photos</span>
          </Space>
        }
      >
        <Upload {...uploadProps}>
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Form.Item>

      {experience?.photos?.length > 0 && (
        <>
          <Divider>Current Photos</Divider>
          <Row gutter={[16, 16]}>
            {experience.photos.map((photo, index) => (
              <Col key={photo.id} xs={24} sm={12} md={8}>
                <Card
                  hoverable
                  cover={
                    <img
                      alt={`Experience photo ${index + 1}`}
                      src={`${process.env.REACT_APP_API_SER}photos/${photo.id}`}
                      style={{
                        height: 200,
                        objectFit: 'cover'
                      }}
                    />
                  }
                  actions={[
                    <Button 
                      danger 
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        const newFileList = fileList.filter(file => file.uid !== photo.id);
                        setFileList(newFileList);
                      }}
                    >
                      Remove
                    </Button>
                  ]}
                />
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  );

  const renderReview = () => (
    <Card>
      <Descriptions title="Experience Review" bordered column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}>
        <Descriptions.Item label="Title">{form.getFieldValue('titre')}</Descriptions.Item>
        <Descriptions.Item label="City">{form.getFieldValue('lieu')}</Descriptions.Item>
        <Descriptions.Item label="Price">{form.getFieldValue('prixParInvite')} MAD</Descriptions.Item>
        <Descriptions.Item label="Max Guests">{form.getFieldValue('nombreMaxInvite')}</Descriptions.Item>
        <Descriptions.Item label="Cuisine Type">{form.getFieldValue('typeCuisine')}</Descriptions.Item>
        <Descriptions.Item label="Date">{form.getFieldValue('temps')?.format('YYYY-MM-DD')}</Descriptions.Item>
        <Descriptions.Item label="Time">
          {form.getFieldValue('heurDebut')?.format('HH:mm')} - {form.getFieldValue('heurFin')?.format('HH:mm')}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <Space direction="vertical" style={{ width: '100%' }}>
        <Title level={4}>Description</Title>
        <Paragraph>{form.getFieldValue('description')}</Paragraph>

        <Title level={4}>Experience Details</Title>
        <Paragraph>
          <strong>Inspiration:</strong> {form.getFieldValue('inspiration')}
        </Paragraph>
        <Paragraph>
          <strong>Duration:</strong> {form.getFieldValue('dureeExperience')}
        </Paragraph>
        <Paragraph>
          <strong>Interaction:</strong> {form.getFieldValue('intercation')}
        </Paragraph>
      </Space>
    </Card>
  );

  const renderContent = (step) => {
    switch (step) {
      case 'basicInfo':
        return renderBasicInfo();
      case 'details':
        return renderDetails();
      case 'photos':
        return renderPhotos();
      case 'review':
        return renderReview();
      default:
        return null;
    }
  };

  return (
    <Card
      title={
        <Space>
          <Title level={3} style={{ margin: 0 }}>Edit Experience</Title>
          <Tag color={experience.isVerified ? 'green' : 'orange'}>
            {experience.isVerified ? 'Verified' : 'Pending Verification'}
          </Tag>
        </Space>
      }
      extra={
        <Space>
          <Button icon={<CloseOutlined />} onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            loading={loading}
            onClick={() => form.submit()}
          >
            Save Changes
          </Button>
        </Space>
      }
      style={{ maxWidth: 1200, margin: '24px auto' }}
    >
      <Steps
        current={currentStep}
        onChange={setCurrentStep}
        items={steps}
        style={{ marginBottom: 24 }}
      />

      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{
          ...experience,
          temps: experience.temps ? dayjs(experience.temps) : null,
          heurDebut: experience.heurDebut ? dayjs(experience.heurDebut, 'HH:mm') : null,
          heurFin: experience.heurFin ? dayjs(experience.heurFin, 'HH:mm') : null,
        }}
      >
        <div className="steps-content">
          {renderContent(steps[currentStep].content)}
        </div>

        <div className="steps-action" style={{ marginTop: 24, textAlign: 'right' }}>
          {currentStep > 0 && (
            <Button 
              style={{ margin: '0 8px' }} 
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button 
              type="primary" 
              onClick={() => setCurrentStep(currentStep + 1)}
            >
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button 
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
            >
              Submit
            </Button>
          )}
        </div>
      </Form>
    </Card>
  );
};

export default EditExperience;
import React from "react";
import "./Header.css";
import Nav from "../comps/Nav";

const Header = () => {
  return (
    <div id="main-container">
      <div id="main-content">
        <div className="header-image">
          <div className="header-content">
            <h1 className="custom-style-primary">
              Explore <br />
              <span className="adelia-font" style={{ marginBottom: "90px" }}>
                your tasty city
              </span>
            </h1>
            <p className="custom-style-secondary">
              Don't eat like a tourist, eat like a local! 🌍🍴
            </p>
            <p className="custom-style-secondary">
              Welcome to Feast & Food, where every dish is <br /> a journey, and
              every bite tells a story.
            </p>
          </div>
          <div className="search-bar-container"></div>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React, { useRef, useState,useEffect } from "react";
import logoBright from "../../img/LogoOrange.png";
import logoDark from "../../img/LogoOrange.png";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import messageIcon from "../../img/Icon.webp";
import notifIcon from "../../img/Icon(1).webp";
import axios from "axios";
function Nav({ Color }) {
  // Déterminer la couleur du texte en fonction du fond
  const textColor = Color === "black" ? "text-grey-text" : "text-black"; // Utiliser text-black pour fond blanc
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [avatarImage, setAvatarImage] = useState("Profile");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_SER+"host/profile/getProfile",
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
            },
          }
        );
        console.log("Profile data:", response.data);
        setAvatarImage(process.env.REACT_APP_API_SER+"photos/"+ response.data.avatar); // Update the profile picture
        console.log("avatarImage",avatarImage);
        localStorage.setItem("avatar", avatarImage);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile(); // Call the async function
  }, [keycloak.token]);
  const handleLogin = () => {
    if (keycloak.authenticated) {
      //navigate("/hostprofile");
    } else {
      keycloak.login();
    }
  };

  const openMenu = () => {
    setIsMenuOpen(true);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <nav className="flex items-center justify-between p-4 md:p-6 lg:p-8 absolute top-0 w-full z-20 bg-white shadow-md">
        <img
          src={Color === "black" ? logoDark : logoBright}
          alt="Feast and Food Logo"
          className="h-16 md:h-20 lg:h-24"
          onClick={() => {
            navigate("/");
          }}
          style={{ cursor: "pointer" }}
        />
        <div className="hidden md:flex space-x-4 lg:space-x-8 h-12">
          <button
            className={`${textColor} poppins-medium text-base md:text-lg`}
          >
            Expériences
          </button>
          <button
            className={`${textColor} poppins-medium text-base md:text-lg`}
          >
            A propos
          </button>
          <button
            className={`${textColor} poppins-medium text-base md:text-lg`}
          >
            Contact
          </button>
          {
            !keycloak.authenticated?
          <>
          <button
            className={`${textColor} poppins-medium text-base md:text-lg`}
            onClick={handleLogin}
          >
            Se connecter
          </button>
          <button
            className="bg-yellow-500 text-white poppins-medium text-base md:text-lg py-2 px-4 rounded"
            onClick={() => {
              navigate("/inscription");
            }}
          >
            S'inscrire
          </button>
          <button
            className={`${textColor} poppins-medium text-base md:text-lg`}
          >
            Devenir hôte
          </button>
          </>
          :<>
          <button className="text-grey-text poppins-medium text-base md:text-lg"
        onClick={()=>navigate('/reservations')}
        >
          Réservations
        </button>
        <button onClick={()=>navigate("/HostProfile")}>
          <img
            src={avatarImage} // Replace with actual profile picture URL or component
            alt="Profile"
            className="h-8 w-8 md:w-16 md:h-16 rounded-full"
          />
        </button>
          </>
          }
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button
            onClick={openMenu}
            style={{
              background: "none",
              border: "none",
              color: "black", // Ensure the text is visible on the white background
              cursor: "pointer",
            }}
          >
            <MenuIcon style={{ fontSize: 32, color: "#d7a022" }} />
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      <div
        ref={menuRef}
        className="nav-menu"
        style={{
          position: "fixed",
          top: "0",
          right: "0",
          height: "100vh",
          width: "300px",
          backgroundColor: "white",
          zIndex: 1000,
          display: "flex",
          flexDirection: "column",
          boxShadow: "-5px 0 15px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s ease-in-out",
          transform: isMenuOpen ? "translateX(0)" : "translateX(100%)",
        }}
      >
        <header
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
            borderBottom: "1px solid #e9ecef",
          }}
        >
          <button
            onClick={closeMenu}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <CloseIcon style={{ fontSize: 28, color: "#495057" }} />
          </button>
        </header>
        <nav style={{ flex: 1, overflowY: "auto" }}>
          <ul
            style={{
              listStyleType: "none",
              padding: "0",
              margin: "0",
            }}
          >
            {[
              { text: "Expériences", path: "/experience" },
              { text: "À propos", path: "/a-propos" },
              { text: "Contact", path: "/contact" },
              { text: "Se connecter", path: "/inscription" },
              { text: "S'inscrire", path: "/inscription" },
              { text: "Devenir hôte", path: "/devenirhote" },
            ].map((item, index) => (
              <li key={index}>
                <button
                  onClick={() => {
                    { 
                      if (item.text === "Se connecter") {
                        handleLogin();
                      } else {
                    navigate(item.path)
                      }
                  }
                    closeMenu();
                  }}
                  style={{
                    width: "100%",
                    padding: "15px 25px",
                    textAlign: "left",
                    background: "none",
                    border: "none",
                    borderBottom: "1px solid #e9ecef",
                    cursor: "pointer",
                    fontSize: "16px",
                    color: "#212529",
                    transition: "background-color 0.2s",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#d7a022")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "transparent")
                  }
                >
                  {item.text}
                </button>
              </li>
            ))}
          </ul>
          {/* Icon Buttons */}
      <div className="hidden md:flex items-center md:space-x-5">
        <button className="text-white">
          <img
            src={messageIcon}
            alt="Message Icon"
            className="h-4 w-6 sm:h-5 sm:w-7 md:h-6 md:w-8"
          />
        </button>
        <button className="text-white">
          <img
            src={notifIcon}
            alt="Notification Icon"
            className="h-4 w-6 sm:h-5 sm:w-7 md:h-6 md:w-8"
          />
        </button>
      </div>
        </nav>
      </div>
    </>
  );
}

export default Nav;

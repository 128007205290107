import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ReviewModal.css';
import { useKeycloak } from "@react-keycloak/web";

const ReviewModal = ({ experienceId, experienceTitle, hostFirstName, reservationId, onClose }) => {
  const { keycloak } = useKeycloak();
  const [step, setStep] = useState(0);
  const [valueRating, setValueRating] = useState(0);
  const [cuisineRating, setCuisineRating] = useState(0);
  const [ambianceRating, setAmbianceRating] = useState(0);
  const [cleanlinessRating, setCleanlinessRating] = useState(0);
  const [comments, setComments] = useState('');
  const [userId, setUserId] = useState(null);

  const steps = [
    { label: 'Rapport qualité/prix', value: valueRating, setValue: setValueRating },
    { label: 'Cuisine', value: cuisineRating, setValue: setCuisineRating },
    { label: 'Ambiance', value: ambianceRating, setValue: setAmbianceRating },
    { label: 'Propreté', value: cleanlinessRating, setValue: setCleanlinessRating },
    { label: 'Commentaires', value: comments, setValue: setComments, isTextArea: true }
  ];

  useEffect(() => {
    if (keycloak.authenticated) {
      const email = keycloak.tokenParsed.email;

      const fetchUserIdByEmail = async () => {
        try {
          const response = await axios.get(`http://localhost:8080/api/user/userIdByEmail`, {
            headers: {
              Authorization: `Bearer ${keycloak.token}`
            },
            params: { email }
          });
          if (response.status === 200) {
            setUserId(response.data);
          } else {
            console.error('Utilisateur non trouvé.');
          }
        } catch (error) {
          console.error('Erreur lors de la récupération de l\'ID utilisateur par email:', error);
        }
      };

      fetchUserIdByEmail();
    }
  }, [keycloak.authenticated, keycloak.tokenParsed.email]);

  const handleSubmit = async () => {
    if (userId === null) {
      console.error('Utilisateur non trouvé.');
      return;
    }

    const review = {
      experienceCulinaire: { id: experienceId },
      user: { id: userId },
      valueRating,
      cuisineRating,
      ambianceRating,
      cleanlinessRating,
      comment: comments,
    };

    try {

      await axios.post('http://localhost:8080/review/add', review, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`
        }
      });


      await axios.put(`http://localhost:8080/reservation/markAsReviewed/${reservationId}`, null, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`
        }
      });
      onClose();
    } catch (error) {
      console.error('Erreur lors de la soumission de l\'avis:', error);
    }
  };


  const handleCloseWithoutReview = async () => {
    try {
      await axios.put(`http://localhost:8080/reservation/rejectReview/${reservationId}`, null, {
        headers: {
          Authorization: `Bearer ${keycloak.token}`
        }
      });
      onClose();
    } catch (error) {
      console.error('Erreur lors de la fermeture du modal sans avis:', error);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content1">
        <span className="close" onClick={handleCloseWithoutReview}>&times;</span>
        {step < steps.length - 1 ? (
          <>
            <p className="modal-title">Merci de partager votre avis sur votre expérience culinaire "{experienceTitle}" chez "{hostFirstName}" :</p>
            <p className="criteria-label">{steps[step].label}</p>
            {steps[step].isTextArea ? (
              <textarea className="text-area" value={steps[step].value} onChange={(e) => steps[step].setValue(e.target.value)} />
            ) : (
              <div className="stars">
                {[1, 2, 3, 4, 5].map((star) => (
                  <span
                    key={star}
                    className={steps[step].value >= star ? 'star filled' : 'star'}
                    onClick={() => steps[step].setValue(star)}
                  >
                    &#9733;
                  </span>
                ))}
              </div>
            )}
            <div className="buttons">
              <button className="btn" onClick={() => setStep(step - 1)} disabled={step === 0}>Retour</button>
              <button className="btn" onClick={() => setStep(step + 1)}>Suivant</button>
            </div>
          </>
        ) : (
          <>
            <p className="modal-title">Si vous avez des suggestions ou un dernier commentaire, partagez-les ici :</p>
            <textarea className="text-area" value={comments} onChange={(e) => setComments(e.target.value)} />
            <button className="btn submit-btn" onClick={handleSubmit}>Soumettre</button>
          </>
        )}
      </div>
    </div>
  );
};

export default ReviewModal;

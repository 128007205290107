import React, { useState,useEffect } from "react";
import { 
  Box, 
  Typography, 
  Dialog, 
  DialogContent, 
  useMediaQuery, 
  useTheme ,Button as MuiButton
} from "@mui/material";
import { Select as MuiSelect, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { Select, Space,Button } from 'antd';
import { Input, Row, Col } from 'antd';
import ReservationPopup from "./Popup";
import ReservationCardMobile from "./ReservationCardMobile";
import keycloak from "../../keycloak";
import { Option } from "antd/lib/mentions";
// Styles personnalisés
const CustomSelect = styled(MuiSelect)(({ theme }) => ({
  backgroundColor: "#ffffff",
  width: "100%",
  borderRadius: "8px",
  marginBottom: "30px",
  "& .MuiSelect-select": {
    padding: "15px",
    "&.Mui-focused": {
      outline: `2px solid #d7a022`,
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    border: `1px solid #d7a022`,
    "&:hover": {
      border: "2px solid #d7a022",
      backgroundColor: "white",
    },
    "&.Mui-focused": {
      border: `2px solid #d7a022`,
    },
    "& fieldset": {
      border: "none",
    },
  },
}));

const DateButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ffffff",
  border: "1px solid transparent",
  "&:hover": {
    border: "2px solid #d7a022",
    backgroundColor: "white",
  },
}));

const ReservationCard = ({ e }) => {
  const [open, setOpen] = useState(false);
  const [selectedGuests, setSelectedGuests] = useState(2);
  const [selectedRecurrenceDate, setSelectedRecurrenceDate] = useState(null);
  const [optionDates, setOptionDates] = useState([e.temps]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    if(!keycloak.authenticated || !keycloak.hasRealmRole("Client")){ 
      alert("Vous devez être connecté en tant que client pour réserver");
      return;  
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Transform recurrence dates for Ant Design Select
  /*
  const recurrenceDateOptions = e.recurenceDates 
    ? e.recurenceDates
        .filter(date => date.frequency)
        .map((date) => ({
          value: date.id,
          label: `${date.jour} ${new Date(date.dateDebut).toLocaleDateString('fr-FR')} - ${date.heurDebut} à ${date.heurFin}`
        }))
    : [];*/

  // Example data for recurrence dates

  useEffect(() => {
    if (e && e.temps && e.recurenceDates) {
      try {
        // Parse the starting date
        const startDate = new Date(e.temps).getTime();

        // Find the maximum date from recurenceDates
        const endDate = Math.max(
          ...e.recurenceDates.map((d) => new Date(d.dateFin).getTime())
        );

        // Generate all dates from startDate to endDate
        const dates = [];
        for (let i = startDate; i <= endDate; i += 24 * 60 * 60 * 1000) {
          dates.push(new Date(i).toISOString().split("T")[0]);
        }

        console.log("Generated dates:", dates); // Debugging output
        setOptionDates(dates);
      } catch (error) {
        console.error("Error generating dates:", error);
      }
    }
  }, [e]);
  const recurrenceDateOptions = e.recurenceDates
    ? e.recurenceDates
        .filter(date => date.frequency) // Keep only dates with frequency
        .map((date) => {
          const startDate = new Date(date.dateDebut);
          const endDate = new Date(date.dateFin);
          const dayOfWeek = new Date(date.dateDebut).getDay();
          
          const daysAvailable = [];
          let currentDate = startDate;

          while (currentDate <= endDate) {
            if (currentDate.getDay() === dayOfWeek) {
              daysAvailable.push({
                value: date.id,
                label: `${date.jour} ${currentDate.toLocaleDateString('fr-FR')} - ${date.heurDebut} à ${date.heurFin}`,
              });
            }
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
          }

          return daysAvailable;
        })
        .flat() // Flatten the array of days
    : [];

  // Handle change event
  const handleRecurrenceDateChange = (value) => {
    setSelectedRecurrenceDate(value); // value is the selected id
  };

  const getDayName = (dateString) => {
    const days = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"];
    const date = new Date(dateString);
    return days[date.getDay()];
  };
  

  if (isMobile) {
    return <ReservationCardMobile e={e} onClick={handleClickOpen} />;
  }

  return (
    <Box
      sx={{
        width: "508px",
        borderRadius: "20px",
        boxShadow: 3,
        marginLeft: "33px",
        marginRight:"300px",
        p: 3,
        "& .MuiFormControl-root": {
          width: "100%",
          mb: 2,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "18px",
          mb: 3,
          mt: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
          }}
        >
          {e.prixParInvite} MAD
          <Typography
            component="span"
            sx={{
              fontFamily: "Poppins",
              display: "inline",
              fontWeight: "400",
              marginLeft: "4px",
              fontSize: "16px",
            }}
          >
            / par invité
          </Typography>
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "16px",
            ml: -3,
            mr: 3,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              display: "inline",
              fontWeight: "400",
              marginLeft: "4px",
              fontSize: "16px",
            }}
          >
            {e.rating == 0 ? 5 : e.rating}/5
            <Typography
              component="span"
              sx={{
                fontFamily: "Poppins",
                display: "inline",
                marginLeft: "4px",
                fontSize: "14px",
              }}
            >
              (500)
            </Typography>
          </Typography>
          <Box
            sx={{
              ml: 0.5,
            }}
          >
            <svg
              width="21"
              height="18"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "4px" }}
            >
              <path
                d="M9.96929 0.572764C10.1659 0.101153 10.8341 0.101153 11.0307 0.572764L13.2046 5.78638C13.2907 5.9928 13.4887 6.13059 13.7121 6.13962L19.7676 6.38422C20.3141 6.4063 20.524 7.10698 20.0797 7.4259L15.4401 10.7556C15.2396 10.8996 15.1533 11.1554 15.2256 11.3914L16.823 16.5999C16.9772 17.1026 16.4316 17.5314 15.9796 17.2629L10.7937 14.1818C10.6127 14.0743 10.3873 14.0743 10.2063 14.1818L5.02041 17.2629C4.56841 17.5314 4.02283 17.1026 4.17699 16.5999L5.77435 11.3914C5.84672 11.1554 5.76041 10.8996 5.55989 10.7556L0.920322 7.4259C0.475957 7.10699 0.68586 6.4063 1.23238 6.38422L7.28785 6.13962C7.51132 6.13059 7.70928 5.9928 7.79536 5.78638L9.96929 0.572764Z"
                fill="#FFE711"
              />
            </svg>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          bgcolor: "#F5F5F5",
          width: "437px",
          borderRadius: "20px",
          padding: "35px",
          gap: "10px",
          marginLeft: "10px",
          textAlign: "left",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "16px",
            marginBottom: "10px",
            fontWeight: "600",
          }}
        >
          Nombre d'invités
        </Typography>
        <MuiSelect
          value={selectedGuests}
          onChange={(e) => setSelectedGuests(e.target.value)}
          sx={{
            backgroundColor: "#ffffff",
            width: "100%",
            borderRadius: "8px",
            marginBottom: "20px",
          }}
        >
          {Array.from({ length: e.nombreMaxInvite }, (_, i) => (
            <MenuItem key={i + 1} value={i + 1}>
              {i + 1} invité{i + 1 > 1 ? 's' : ''}
            </MenuItem>
          ))}
        </MuiSelect>

        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "16px",
            marginBottom: "10px",
            fontWeight: "600",
          }}
        >
          Date de réservation
        </Typography>
        
        <div>
    <Row gutter={[8, 8]} style={{ marginBottom: '16px' }}>
      {optionDates.slice(0, 4).map((option) => (
        <Col key={option}>
          <Button
            type={selectedRecurrenceDate === option ? 'primary' : 'default'}
            onClick={() => handleRecurrenceDateChange(option)}
            style={{
              borderRadius: '8px',
              padding: '8px 16px',
              font:"Poppins",
              fontWeight: selectedRecurrenceDate === option ? 'bold' : 'normal',
              backgroundColor: selectedRecurrenceDate === option ? '#d7a022' : 'white',
              height: "60px",
            }}
          >
            {new Date(option).toLocaleDateString('fr-FR', {
              day: '2-digit',
              month: 'short',
            })}
          </Button>
        </Col>
      ))}
    </Row>
    <Select
      showSearch
      style={{ width: '100%',height:"100px", borderRadius: '8px', height: '60px' }}
      placeholder="Sélectionnez une date de réservation"
      value={selectedRecurrenceDate}
      onChange={handleRecurrenceDateChange}
      optionFilterProp="label"
      filterOption={(input, option) =>
        option?.label?.toLowerCase().includes(input.toLowerCase())
      }
      options={optionDates.map((option) => ({
        value: option,
        label: `${getDayName(option)} ${new Date(option).toLocaleDateString('fr-FR')} - 13h00`,
      }))}
    />
  </div>

        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            fontFamily: "Poppins",
            color: "black",
            fontSize: "16px",
          }}
        >
          Annulation sans frais jusqu'à 1 jour avant la date de réservation.
        </Typography>
      </Box>
      {
 selectedRecurrenceDate?
 <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 3,
        }}
      >
        <MuiButton
          variant="contained"
          sx={{
            backgroundColor: "#d7a022",
            width: "380px",
            padding: "10px",
            borderRadius: "10px",
            fontFamily: "Poppins",
            textTransform: "none",
            fontSize: "18px",
            "&:hover": { backgroundColor: "#d7a022" },
          }}
          onClick={handleClickOpen}
        >
          Réserver
        </MuiButton>
      </Box>
:<Box sx={{display: "flex",
justifyContent: "center",
mt: 3}}>Cette expérience n'est pas disponible pour le moment.</Box>}
      <Dialog
  open={open}
  onClose={handleClose}
  PaperProps={{
    style: {
      boxShadow: "none",
      backgroundColor: "transparent",
    },
  }}
>
  <DialogContent>
    {keycloak.hasRealmRole("Client") && 
      <ReservationPopup 
        id={e.id} 
        prix={e.prixParInvite} 
        setOpen={setOpen}
        guestCount={selectedGuests}
        inforeservation={selectedRecurrenceDate}
      />
    }
  </DialogContent>
</Dialog>

    </Box>
  );
};

export default ReservationCard;
import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography, Checkbox, FormGroup, FormControlLabel, Button, Grid, Container, Pagination } from '@mui/material';
import { useKeycloak } from "@react-keycloak/web";
import NavHote from '../../Host/NavHote';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom';

const checkboxstyle = {
    marginLeft: "10px",
    marginRight: "10px",
    width: 20,
    height: 20,
    position: 'relative',
    '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        border: '2px solid #B0B0B0',
        borderRadius: '5px',
        backgroundColor: 'white',
    },
    '&.Mui-checked::before': {
        backgroundColor: '#B88454',
        borderColor: '#7C7C7C',
    },
    '&.Mui-checked::after': {
        content: '""',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        color: 'white',
        fontSize: '16px',
    },
};

export default function Experience() {
    const [events, setEvents] = useState([]);
    const { keycloak } = useKeycloak();
    const [filter, setFilter] = useState('En ligne');
    const [page, setPage] = useState(1);
    const itemsPerPage = 10; // Display 10 items per page
    const navigate = useNavigate();
    useEffect(() => {
        // Store the original viewport settings to restore later
        const originalViewport = document.querySelector("meta[name=viewport]");
        
        // Create a new meta tag to simulate a desktop view on mobile
        const meta = document.createElement('meta');
        meta.name = 'viewport';
        meta.content = 'width=1024'; // Force desktop width
        document.getElementsByTagName('head')[0].appendChild(meta);
        
        // Cleanup function to remove the desktop-like settings when component is unmounted
        return () => {
          document.getElementsByTagName('head')[0].removeChild(meta);
          
          // Restore original viewport settings (if they exist)
          if (originalViewport) {
            document.getElementsByTagName('head')[0].appendChild(originalViewport);
          }
        };
      }, []);
    useEffect(() => {
        const fetchExperiences = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_SER}experience/hostn?f=0&e=120`, {
                    headers: {
                        Authorization: `Bearer ${keycloak.token}`,
                        "Content-Type": "application/json",
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (Array.isArray(data)) {
                    setEvents(data);
                } else {
                    console.error('Expected an array but got:', data);
                }
            } catch (error) {
                console.error('Error fetching experiences:', error);
            }
        };

        fetchExperiences();
    }, [keycloak]);

    // Calculate the items to display based on the current page
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentEvents = events.slice(startIndex, endIndex);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <>
            <NavHote />
            <Container>
                <div style={{ marginTop: "200px" }}></div>
                <Button variant="contained" style={{ float: "right", backgroundColor: "#D7A022", fontFamily: "Poppins" }} onClick={() => navigate("/newannonce")}>Déposer une nouvelle annonce</Button>
                <Typography variant="h4">Mes annonces</Typography>
                <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
                            <Pagination
                                count={Math.ceil(events.length / itemsPerPage)}
                                page={page}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Grid>
                <Grid container spacing={2} style={{ marginBottom: "20px" }}>

                    <Grid item xs={3}>
                        <FormGroup style={{
                            backgroundColor: "#F8F8F8",
                            border: "1px solid #B0B0B0",
                            borderRadius: "8px",
                            padding: "16px",
                            gap: "10px",
                            fontFamily: "Poppins"
                        }}>
                            <Typography variant="h6" style={{ marginBottom: "8px" }}>
                                Filters
                            </Typography>
                            <FormControlLabel
                                control={<Checkbox
                                    sx={checkboxstyle}
                                    checked={filter === 'En ligne'} value="En ligne" />}
                                label="En ligne"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={filter === 'En cours de vérification'}
                                    sx={checkboxstyle} value="En cours de vérification" />}
                                label="En cours de vérification"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={filter === 'Non terminé'}
                                    sx={checkboxstyle} value="Non terminé" />}
                                label="Non terminé"
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    checked={filter === 'Archivé'}
                                    value="Archivé"
                                    sx={checkboxstyle}
                                />}
                                label="Archivé"
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={9}>
                        <Grid container spacing={3}>
                            {currentEvents.map(event => (
                                <Grid item xs={12} sm={6} md={4} key={event.id}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        style={{ height: "230px" }}
                                        image={`${process.env.REACT_APP_API_SER}photos/${event.photos[0].id}`}
                                        alt={event.titre}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {event.titre}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {event.description}
                                        </Typography>
                                    </CardContent>
                                    <Button onClick={() => navigate('/annonce', { state: event })} style={{ width: "100%", backgroundColor: "rgba(225, 189, 62, 0.21)", color: "#B88454", fontSize: "10px", fontFamily: "Poppins" }}>
                                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.48089 2.06128H2.62671C1.85869 2.06128 1.23608 2.68388 1.23608 3.4519V8.08736C1.23608 8.85538 1.85869 9.47798 2.62671 9.47798H7.26215C8.03018 9.47798 8.65278 8.85538 8.65278 8.08736V6.23315M6.33484 2.06139L8.65278 2.06128M8.65278 2.06128V4.14727M8.65278 2.06128L4.71232 6.00127" stroke="#B88454" stroke-width="0.824078" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        &nbsp;&nbsp;
                                        Voir l'apperçu
                                    </Button>
                                    <Button onClick={() => navigate('/edit-annonce', { state: event })} style={{ width: "100%", backgroundColor: "rgba(62, 189, 225, 0.21)", color: "#4487B8", fontSize: "10px", fontFamily: "Poppins" }}>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 11H11M1 6.5L6.5 1L8.5 3L3 8.5H1V6.5Z" stroke="#4487B8" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        &nbsp;&nbsp;
                                        Éditer
                                    </Button>
                                    <Button style={{ width: "100%", backgroundColor: "rgba(124, 124, 124, 0.10)", color: "#7C7C7C", fontSize: "10px", fontFamily: "Poppins" }}>
                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.92285 3.8131H9.61424M4.32641 2.28613H7.21068M4.80712 8.90299V5.84906M6.72997 8.90299V5.84906M7.45104 10.9389H4.08606C3.55508 10.9389 3.12463 10.4832 3.12463 9.92097L2.90514 4.34328C2.89376 4.05411 3.11209 3.8131 3.38543 3.8131H8.15166C8.425 3.8131 8.64334 4.05411 8.63196 4.34328L8.41246 9.92097C8.41246 10.4832 7.98202 10.9389 7.45104 10.9389Z" stroke="#7C7C7C" stroke-width="0.961424" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        &nbsp;&nbsp;
                                        Archiver
                                    </Button>
                                </Card>
                            </Grid>
                            ))}
                        </Grid>
                       
                    </Grid>

                </Grid>

            </Container>
            <Footer />
        </>
    );
}

import React from "react";
import "./Footer.css";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import logo from "../img/LogoSignUp.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-logo">
          <center>
            <img src={logo} alt="Feast and Food" />
          </center>
        </div>
        <nav className="footer-navigation">
          <ul className="navigation-list">
            <li className="navigation-item">
              <Link to="/">Accueil</Link>
            </li>
            <li className="navigation-item">
              <a href="#">Expériences</a>
            </li>
            <li className="navigation-item">
              <a href="#">À propos</a>
            </li>
            <li className="navigation-item">
              <a href="#">Contact</a>
            </li>
          </ul>
        </nav>
        <div className="footer-description">
          <p>
            Welcome to Feast & Food, where every dish is a journey, and <br />
            every bite tells a story 🍽️
          </p>
        </div>
        <div className="social-media">
          <a href="#" className="social-icon">
            <FaFacebook />
          </a>
          <a href="#" className="social-icon">
            <FaTwitter />
          </a>
          <a href="#" className="social-icon">
            <FaLinkedin />
          </a>
          <a href="#" className="social-icon">
            <FaInstagram />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import {
  Table, Card, Space, Input, Button, Modal, Typography, Row, Col,
  Statistic, Tag, Image, Carousel, Divider, Select, Form,
  Alert, Badge, Progress, DatePicker, Switch, Rate,
  Popconfirm, Radio, Tabs, Empty, Spin, List, Avatar
} from 'antd';
import {
  CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined,
  EditOutlined, EyeOutlined, FilterOutlined, PictureOutlined,
  DollarOutlined, TeamOutlined, StarOutlined, ClockCircleOutlined,
  EnvironmentOutlined, FireOutlined, LineChartOutlined,
  RiseOutlined, CalendarOutlined, SearchOutlined
} from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';

import { LineChart, BarChart, PieChart, Bar, Pie, Line, XAxis, YAxis, 
  CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';

const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const ExperiencesDashboard = () => {
  // State Management
  const [experiences, setExperiences] = useState([]);
  const [filteredExperiences, setFilteredExperiences] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [imagePreviewModal, setImagePreviewModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewMode, setViewMode] = useState('table');
  const [dateRange, setDateRange] = useState(null);
  const [filters, setFilters] = useState({
    city: '',
    priceRange: null,
    rating: null,
    verified: null,
    cuisine: '',
  });

  // Analytics State
  const [analytics, setAnalytics] = useState({
    totalExperiences: 0,
    verifiedExperiences: 0,
    averageRating: 0,
    totalRevenue: 0,
    popularCities: [],
    priceDistribution: [],
    monthlyTrends: [],
    cuisineTypes: [],
    bookingTrends: [],
    hostPerformance: [],
  });

  useEffect(() => {
    fetchExperiences();
  }, []);

  useEffect(() => {
    if (experiences.length > 0) {
      calculateAnalytics();
      applyFilters();
    }
  }, [experiences, filters, dateRange]);

  const fetchExperiences = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SER}experience/all/0/120`);
      setExperiences(res.data);
      setFilteredExperiences(res.data);
    } catch (error) {
      toast.error('Failed to load experiences');
    } finally {
      setLoading(false);
    }
  };

  const calculateAnalytics = () => {
    const verifiedCount = experiences.filter(exp => exp.isVerified).length;
    const avgRating = experiences.reduce((acc, exp) => acc + (exp.rating || 0), 0) / experiences.length;
    const totalRev = experiences.reduce((acc, exp) => acc + (exp.prixParInvite * exp.nombreMaxInvite), 0);

    // City distribution
    const cityData = experiences.reduce((acc, exp) => {
      acc[exp.lieu] = (acc[exp.lieu] || 0) + 1;
      return acc;
    }, {});

    // Price distribution
    const priceRanges = {
      'Budget (<200 MAD)': 0,
      'Mid-range (200-500 MAD)': 0,
      'Premium (>500 MAD)': 0
    };
    experiences.forEach(exp => {
      if (exp.prixParInvite < 200) priceRanges['Budget (<200 MAD)']++;
      else if (exp.prixParInvite <= 500) priceRanges['Mid-range (200-500 MAD)']++;
      else priceRanges['Premium (>500 MAD)']++;
    });

    // Generate mock monthly data (in real app, this would come from backend)
    const monthlyData = [
      { month: 'Jan', experiences: experiences.length - 10 },
      { month: 'Feb', experiences: experiences.length - 8 },
      { month: 'Mar', experiences: experiences.length - 5 },
      { month: 'Apr', experiences: experiences.length }
    ];

    setAnalytics({
      totalExperiences: experiences.length,
      verifiedExperiences: verifiedCount,
      averageRating: avgRating,
      totalRevenue: totalRev,
      popularCities: Object.entries(cityData).map(([city, count]) => ({
        name: city,
        value: count
      })),
      priceDistribution: Object.entries(priceRanges).map(([range, count]) => ({
        name: range,
        value: count
      })),
      monthlyTrends: monthlyData,
      cuisineTypes: [...new Set(experiences.map(exp => exp.typeCuisine))],
      bookingTrends: monthlyData,
      hostPerformance: experiences
        .filter(exp => exp.host)
        .slice(0, 5)
        .map(exp => ({
          name: `${exp.host.prenom} ${exp.host.nom}`,
          rating: exp.rating || 0,
          experiences: 1
        }))
    });
  };

  const applyFilters = () => {
    let filtered = [...experiences];

    if (filters.city) {
      filtered = filtered.filter(exp => 
        exp.lieu?.toLowerCase().includes(filters.city.toLowerCase())
      );
    }

    if (filters.priceRange) {
      const [min, max] = filters.priceRange;
      filtered = filtered.filter(exp => 
        exp.prixParInvite >= min && exp.prixParInvite <= max
      );
    }

    if (filters.rating) {
      filtered = filtered.filter(exp => exp.rating >= filters.rating);
    }

    if (filters.verified !== null) {
      filtered = filtered.filter(exp => exp.isVerified === filters.verified);
    }

    if (filters.cuisine) {
      filtered = filtered.filter(exp => 
        exp.typeCuisine?.toLowerCase() === filters.cuisine.toLowerCase()
      );
    }

    if (dateRange) {
      const [start, end] = dateRange;
      filtered = filtered.filter(exp => {
        const expDate = dayjs(exp.createdAt);
        return expDate.isAfter(start) && expDate.isBefore(end);
      });
    }

    setFilteredExperiences(filtered);
  };

  const columns = [
    {
      title: 'Title & Host',
      key: 'title',
      fixed: 'left',
      width: 300,
      render: (text, record) => (
        <Space direction="vertical" size={0}>
          <Text strong>{record.titre}</Text>
          <Text type="secondary">
            by {record.host?.prenom} {record.host?.nom}
          </Text>
        </Space>
      )
    },
    {
      title: 'Location & Price',
      key: 'location',
      width: 200,
      render: (text, record) => (
        <Space direction="vertical" size={0}>
          <Space>
            <EnvironmentOutlined />
            <Text>{record.lieu || 'N/A'}</Text>
          </Space>
          <Space>
            <DollarOutlined />
            <Text>{record.prixParInvite} MAD</Text>
          </Space>
        </Space>
      )
    },
    {
      title: 'Details',
      key: 'details',
      width: 200,
      render: (text, record) => (
        <Space direction="vertical" size={0}>
          <Space>
            <TeamOutlined />
            <Text>Max {record.nombreMaxInvite} guests</Text>
          </Space>
          <Space>
            <StarOutlined />
            <Text>{record.rating ? `${record.rating}/5` : 'No ratings'}</Text>
          </Space>
        </Space>
      )
    },
    {
      title: 'Status',
      key: 'status',
      width: 150,
      render: (text, record) => (
        <Space>
          <Badge
            status={record.isVerified ? 'success' : 'error'}
            text={
              <Tag color={record.isVerified ? 'success' : 'error'}>
                {record.isVerified ? 'Verified' : 'Not Verified'}
              </Tag>
            }
          />
        </Space>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 200,
      render: (text, record) => (
        <Space>
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => handleViewDetails(record)}
          >
            View
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this experience?"
            onConfirm={() => handleDeleteExperience(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      )
    }
  ];

  const handleViewDetails = (experience) => {
    setSelectedExperience(experience);
    setModalVisible(true);
  };

  const handleDeleteExperience = async (id) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_SER}experience/delete/${id}`);
      setExperiences(prev => prev.filter(exp => exp.id !== id));
      toast.success('Experience deleted successfully');
    } catch (error) {
      toast.error('Failed to delete experience');
    }
  };

  const handleStatusChange = async (id, verified) => {
    try {
      const endpoint = verified ? 'enable' : 'disable';
      await axios.post(`${process.env.REACT_APP_API_SER}experience/${endpoint}/${id}`);
      setExperiences(prev =>
        prev.map(exp =>
          exp.id === id ? { ...exp, isVerified: verified } : exp
        )
      );
      toast.success(`Experience ${verified ? 'verified' : 'unverified'} successfully`);
      setModalVisible(false);
    } catch (error) {
      toast.error(`Failed to ${verified ? 'verify' : 'unverify'} experience`);
    }
  };

  const AnalyticsView = () => (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Experiences"
              value={analytics.totalExperiences}
              prefix={<FireOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Verified Experiences"
              value={analytics.verifiedExperiences}
              prefix={<CheckCircleOutlined />}
              suffix={`/ ${analytics.totalExperiences}`}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Average Rating"
              value={analytics.averageRating.toFixed(2)}
              prefix={<StarOutlined />}
              suffix="/5"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Revenue Potential"
              value={analytics.totalRevenue}
              prefix={<DollarOutlined />}
              suffix="MAD"
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Card title="Experience Growth Trend">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={analytics.monthlyTrends}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                <Line type="monotone" dataKey="experiences" stroke="#1890ff" />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Price Distribution">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={analytics.priceDistribution}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#1890ff"
                  label
                />
                <RechartsTooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Card title="Popular Cities">
            <List
              dataSource={analytics.popularCities}
              renderItem={item => (
                <List.Item>
                  <Space>
                    <EnvironmentOutlined />
                    <Text>{item.name}</Text>
                    <Progress
                      percent={(item.value / analytics.totalExperiences * 100).toFixed(1)}
                      size="small"
                    />
                  </Space>
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Top Performing Hosts">
            <List
              dataSource={analytics.hostPerformance}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<UserOutlined />} />}
                    title={item.name}
                    description={
                      <Space>
                        <Rate disabled defaultValue={item.rating} />
                        <Text>({item.experiences} experiences)</Text>
                      </Space>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </Space>
  );

  
          {/* Previous imports and code remain the same until the return statement */}

return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Card>
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          <Col>
            <Title level={4}>Experiences Management Dashboard</Title>
          </Col>
          <Col>
            <Space>
              <Radio.Group value={viewMode} onChange={e => setViewMode(e.target.value)}>
                <Radio.Button value="table">Table View</Radio.Button>
                <Radio.Button value="analytics">Analytics View</Radio.Button>
              </Radio.Group>
            </Space>
          </Col>
        </Row>
        
        <Divider />
        
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Input
              placeholder="Filter by city"
              prefix={<EnvironmentOutlined />}
              onChange={e => setFilters(prev => ({ ...prev, city: e.target.value }))}
              value={filters.city}
            />
          </Col>
          <Col span={6}>
            <Select
              style={{ width: '100%' }}
              placeholder="Price Range"
              onChange={value => setFilters(prev => ({ ...prev, priceRange: value }))}
              value={filters.priceRange}
            >
              <Option value={[0, 200]}>Budget (&lt;200 MAD)</Option>
              <Option value={[200, 500]}>Mid-range (200-500 MAD)</Option>
              <Option value={[500, Infinity]}>Premium (&gt;500 MAD)</Option>
            </Select>
          </Col>
          <Col span={6}>
            <Select
              style={{ width: '100%' }}
              placeholder="Minimum Rating"
              onChange={value => setFilters(prev => ({ ...prev, rating: value }))}
              value={filters.rating}
            >
              {[5, 4, 3, 2, 1].map(rating => (
                <Option key={rating} value={rating}>
                  {rating}+ Stars
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <Select
              style={{ width: '100%' }}
              placeholder="Verification Status"
              onChange={value => setFilters(prev => ({ ...prev, verified: value }))}
              value={filters.verified}
            >
              <Option value={true}>Verified</Option>
              <Option value={false}>Not Verified</Option>
            </Select>
          </Col>
        </Row>
        
        <Row style={{ marginTop: 16 }}>
          <Col span={24}>
            <RangePicker
              style={{ width: 300 }}
              onChange={dates => setDateRange(dates)}
              value={dateRange}
            />
          </Col>
        </Row>
      </Card>
  
      {loading ? (
        <div style={{ textAlign: 'center', padding: '50px' }}>
          <Spin size="large" />
        </div>
      ) : (
        viewMode === 'table' ? (
          <Card>
            <Table
              columns={columns}
              dataSource={filteredExperiences}
              rowKey="id"
              scroll={{ x: 1200 }}
              pagination={{
                pageSize: 10,
                showSizeChanger: true,
                showTotal: total => `Total ${total} experiences`
              }}
            />
          </Card>
        ) : (
          <AnalyticsView />
        )
      )}
  
  <Modal
  title="Experience Details"
  open={modalVisible}
  onCancel={() => setModalVisible(false)}
  width={800}
  footer={[
    <Button key="close" onClick={() => setModalVisible(false)}>
      Close
    </Button>,
    selectedExperience && (
      <Switch
        key="status"
        checked={selectedExperience.isVerified}
        onChange={(checked) =>
          handleStatusChange(selectedExperience.id, checked)
        }
        checkedChildren="Verified"
        unCheckedChildren="Not Verified"
      />
    ),
  ]}
>
  {selectedExperience && (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      {/* Experience Header */}
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <Title level={5}>{selectedExperience.titre}</Title>
          <Text type="secondary">
            by {selectedExperience.host?.prenom} {selectedExperience.host?.nom}
          </Text>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Tag color={selectedExperience.isVerified ? "success" : "error"}>
            {selectedExperience.isVerified ? "Verified" : "Not Verified"}
          </Tag>
        </Col>
      </Row>

      <Divider />

      {/* Core Details */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Space direction="vertical">
            <Text strong>Location</Text>
            <Space>
              <EnvironmentOutlined />
              <Text>{selectedExperience.lieu}</Text>
            </Space>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Text strong>Price per Guest</Text>
            <Space>
              <DollarOutlined />
              <Text>{selectedExperience.prixParInvite} MAD</Text>
            </Space>
          </Space>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Space direction="vertical">
            <Text strong>Maximum Guests</Text>
            <Space>
              <TeamOutlined />
              <Text>{selectedExperience.nombreMaxInvite}</Text>
            </Space>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Text strong>Rating</Text>
            <Rate disabled defaultValue={selectedExperience.rating || 0} />
          </Space>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Space direction="vertical">
            <Text strong>Start Time</Text>
            <Text>{selectedExperience.heurDebut}</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Text strong>End Time</Text>
            <Text>{selectedExperience.heurFin}</Text>
          </Space>
        </Col>
      </Row>

      <Divider />

      {/* Host Details */}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Host Information">
            <Space direction="vertical" style={{ width: "100%" }}>
              <Text strong>Bio:</Text>
              <Text>{selectedExperience.host?.bio || "N/A"}</Text>
              <Text strong>Anecdote:</Text>
              <Text>{selectedExperience.host?.anecdote || "N/A"}</Text>
              <Text strong>Passions:</Text>
              <Text>{selectedExperience.host?.passions || "N/A"}</Text>
              <Text strong>Languages:</Text>
              <Text>{selectedExperience.host?.langues || "N/A"}</Text>
            </Space>
          </Card>
        </Col>
      </Row>

      <Divider />

      {/* Additional Information */}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Space direction="vertical">
            <Text strong>Cuisine Type</Text>
            <Text>{selectedExperience.typeCuisine || "N/A"}</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Text strong>Duration</Text>
            <Text>{selectedExperience.dureeExperience || "N/A"}</Text>
          </Space>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Space direction="vertical">
            <Text strong>Interaction Style</Text>
            <Text>{selectedExperience.intercation || "N/A"}</Text>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Text strong>Precautions</Text>
            <Text>{selectedExperience.precautions || "N/A"}</Text>
          </Space>
        </Col>
      </Row>

      <Divider />

      {/* Experience Images */}
      

      {selectedExperience.photos?.length > 0 && (
  <Card 
    title={<Text strong style={{ fontSize: "18px" }}>Experience Images</Text>} 
    style={{ marginTop: "20px", borderRadius: "10px", overflow: "hidden", boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)" }}
    headStyle={{ backgroundColor: "#f5f5f5" }}
    bodyStyle={{ padding: "15px" }}
  >
    <Carousel 
      autoplay 
      dots 
      arrows 
      draggable 
      style={{ width: "100%", maxHeight: 500 }}
      dotPosition="bottom"
      effect="fade"
    >
      {selectedExperience.photos.map((image, index) => (
        <div 
          key={index} 
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 500,
            overflow: "hidden",
            borderRadius: "10px",
          }}
        >
          <Image
            src={`${process.env.REACT_APP_API_SER}photos/${image.id}`}
            alt={`Experience image ${index + 1}`}
            preview
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
              transition: "transform 0.3s ease-in-out",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.05)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          />
          <div 
            style={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              color: "white",
              padding: "8px 15px",
              borderRadius: "20px",
              fontSize: "14px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
              textAlign: "center",
            }}
          >
            Image {index + 1} / {selectedExperience.photos.length}
          </div>
        </div>
      ))}
    </Carousel>
  </Card>
)}



      
    </Space>
  )}
</Modal>

    </Space>
  );
                    }
  export default ExperiencesDashboard;
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem"; // Importation corrigée
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { FormHelperText, NativeSelect } from "@mui/material";
import { Search } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "orange",
  width: "50px", // Largeur définie
  height: "50px", // Hauteur égale à la largeur
  borderRadius: "50%", // Forme circulaire
  "&:hover": {
    backgroundColor: "darkorange",
  },
  "& svg": {
    color: "white",
    fontSize: "24px", // Ajuster la taille de l'icône si nécessaire
  },
}));

const SearchButton = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <StyledIconButton>
        <Search />
      </StyledIconButton>
    </Box>
  );
};

export default function ColorTextFields() {
  const [ville, setVille] = React.useState("");

  const handleChange = (event) => {
    setVille(event.target.value);
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        boxShadow: 3,
        marginBottom: "26px",
        flexDirection: "column",
        padding: "10px",
        borderRadius: "50px",
        backgroundColor: "#fff",
        alignItems: "center", // Aligner les éléments au centre verticalement
        "& > :not(style)": { m: 1.2, width: "18ch" }, // Espacement entre les éléments
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        label="Plat"
        placeholder="rechercher un plat"
        variant="standard"
        color="warning"
        focused
        InputLabelProps={{
          style: {
            fontWeight: "bold",
            color: "black",
          },
        }}
        sx={{
          "& .MuiInput-underline:before": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
      />
      <FormControl fullWidth>
        <InputLabel
          variant="standard"
          htmlFor="uncontrolled-native"
          sx={{ fontWeight: "bold", color: "black" }}
        >
          Ville
        </InputLabel>
        <NativeSelect
          defaultValue={"Choisir une ville"}
          inputProps={{
            name: "ville",
            id: "uncontrolled-native",
          }}
        >
          <option value={"Choisir une ville"}>Choisir une ville</option>
          <option value="Tanger">Tanger</option>
          <option value="Marrakech">Marrakech</option>
          <option value="Casablanca">Casablanca</option>
          <option value="Rabat">Rabat</option>
          <option value="Fes">Fes</option>
        </NativeSelect>
      </FormControl>
      <TextField
        label="Lieu"
        placeholder="Où ?"
        variant="standard"
        color="warning"
        focused
        InputLabelProps={{
          style: {
            fontWeight: "bold",
            color: "black",
          },
        }}
        sx={{
          "& .MuiInput-underline:before": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
      />
      <TextField
        label="Date"
        placeholder="Quand ?"
        type="date"
        variant="standard"
        color="warning"
        focused
        InputLabelProps={{
          style: {
            fontWeight: "bold",
            color: "black",
          },
        }}
        sx={{
          "& .MuiInput-underline:before": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
      />
      <SearchButton />
    </Box>
  );
}

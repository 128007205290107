import React from 'react';
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { Card } from 'antd';

const DashboardCharts = ({ statistiques }) => {
  // Data for the reservation status pie chart
  const reservationStatusData = [
    { name: 'Approuvées', value: statistiques.reservationsApprouvees },
    { name: 'En Attente', value: statistiques.reservationsEnAttente },
    { name: 'Rejetées', value: statistiques.reservationsRejetees },
  ];

  // Sample monthly revenue data
  const monthlyRevenueData = [
    { month: 'Jan', revenue: 1200 },
    { month: 'Fév', revenue: 1900 },
    { month: 'Mar', revenue: 1500 },
    { month: 'Avr', revenue: 2400 },
    { month: 'Mai', revenue: 2800 },
    { month: 'Jun', revenue: 3200 },
  ];

  // Sample occupancy rate data
  const occupancyData = [
    { month: 'Jan', taux: 65 },
    { month: 'Fév', taux: 72 },
    { month: 'Mar', taux: 78 },
    { month: 'Avr', taux: 85 },
    { month: 'Mai', taux: 82 },
    { month: 'Jun', taux: statistiques.tauxOccupation },
  ];

  const COLORS = ['#52c41a', '#faad14', '#ff4d4f'];

  return (
    <div className="w-full mt-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Pie Chart for Reservation Status */}
        <Card title="Statut des Réservations">
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={reservationStatusData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  paddingAngle={5}
                  dataKey="value"
                >
                  {reservationStatusData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Card>

        {/* Line Chart for Revenue Trend */}
        <Card title="Évolution des Revenus">
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={monthlyRevenueData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="revenue"
                  stroke="#8884d8"
                  strokeWidth={2}
                  name="Revenu (€)"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Card>

        {/* Bar Chart for Occupancy Rate */}
        <Card title="Taux d'Occupation Mensuel" className="md:col-span-2">
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={occupancyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="taux"
                  fill="#1890ff"
                  name="Taux d'occupation (%)"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default DashboardCharts;

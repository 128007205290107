import React, { useEffect, useState } from "react";
import {
  Paper,
  InputBase,
  Select,
  MenuItem,
  InputAdornment,
  Box,
  IconButton,
  styled,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  borderRadius: "90px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  maxWidth: "400px",
  margin: "8px",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "14px",
    fontWeight: 500,
    color: "black",
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    paddingRight: "32px !important",
    fontSize: "14px",
    fontWeight: 400,
  },
}));

const MobileSearchBar = ({
  searchTerm,
  setSearchTerm,
  selectedCity,
  setSelectedCity,
  handleSearch,
  cityOptions = [],
}) => {
  useEffect(() => {
    if (selectedCity && !cityOptions.includes(selectedCity)) {
      setSelectedCity("");
    }
  }, [selectedCity, cityOptions, setSelectedCity]);

  const [selectedDate, setSelectedDate] = useState("2024-08-13");

  return (
    <StyledPaper elevation={3} style={{ marginTop: "-10px" }}>
      <Box mb={2} mt={-1}>
        <StyledInputBase
          fullWidth
          placeholder="Rechercher un plat"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={<InputAdornment position="start"></InputAdornment>}
        />
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4.2} sx={{ marginTop: "-40px" }}>
          <StyledSelect
            fullWidth
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            displayEmpty
            IconComponent={KeyboardArrowDownIcon}
            renderValue={(value) => {
              if (value === "") {
                return (
                  <em style={{ color: "#757575", fontStyle: "normal" }}>
                    Ville
                  </em>
                );
              }
              return value;
            }}
            inputProps={{ "aria-label": "Ville" }}
            sx={{
              border: "none",
              boxShadow: "none",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <MenuItem disabled value="">
              <em>Choisir une ville</em>
            </MenuItem>
            {cityOptions.map((city) => (
              <MenuItem key={city} value={city}>
                {city}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>

        <Grid item xs={2.5} sx={{ marginTop: "-45px" }}>
          <StyledInputBase
            fullWidth
            placeholder="Lieu"
            startAdornment={<InputAdornment position="start"></InputAdornment>}
          />
        </Grid>

        <Grid item xs={4.2} sx={{ marginTop: "-46px", marginLeft: "-8px" }}>
          <StyledInputBase
            fullWidth
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            placeholder="Date"
            inputProps={{
              style: {
                fontSize: "12px",
                fontWeight: 400,
                color: selectedDate ? "inherit" : "#757575",
              },
            }}
          />
        </Grid>
      </Grid>
      <Box mt={-7.8} display="flex" justifyContent="flex-end">
        <IconButton
          size="medium"
          sx={{
            bgcolor: "#d7a022",
            color: "white",
            "&:hover": { bgcolor: "darkorange" },
          }}
          onClick={handleSearch}
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </StyledPaper>
  );
};

export default MobileSearchBar;

import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <ReactKeycloakProvider
  //   authClient={keycloak}
  //   initOptions={{
  //     onLoad: "check-sso",
  //     silentCheckSsoRedirectUri:
  //       window.location.origin + "/silent-check-sso.html",
  //   }}>
  //   <BrowserRouter>
  <App />
  //   </BrowserRouter>
  // </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

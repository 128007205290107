import React, { useEffect } from "react";
import "./ProfileCard.css";
import ProfileHeader from "./ProfileHeader";
import ProfileAbout from "./ProfileAbout";
import Footer from "../../components/Footer.js";
import Nav from "../Annonce/Nav";
import Food from "./Food";
import Container from "@mui/material/Container";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function Profile() {
  const navigate = useNavigate();
  const location = useLocation();
  const e = location.state || JSON.parse(localStorage.getItem("eData"));

  useEffect(() => {
    console.log("test 15/10");
    console.log(e);
  }, [e]);

  const { host, photos, titre, typeCuisine, prixParInvite, rating, description } = e;
  const [experience, setExperience] = useState([]);
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_SER + "experience/hostexp/" + e.host.id).then((response) => {
      setExperience(response.data);
      console.log(response.data);
    });
  }, [e]);
  return (
    <>
      <Nav color="light" />
      <div style={{ marginTop: "150px" }}></div>

      <Container maxWidth="lg">
        {/* Grid layout for larger screens */}
        <Grid container spacing={4}>
          {/* Left Column - Profile Header */}
          <Grid item xs={12} md={4}>
            {
                window.innerWidth > 600 &&
            <div style={{ marginTop: "50px" }}></div>}
            <ProfileHeader
              name={host.nom}
              location={e.lieu}
              experienceYears="2 ans"
              avatar={host.avatar}
            />
          </Grid>

          {/* Right Column - Profile About */}
          <Grid item xs={12} md={8}>
            <ProfileAbout
              etudes={host.etudes}
              travail={host.travail}
              anecdote={host.anecdote}
              chanson={host.chanson}
              decennieNaissance={host.decennieNaissance}
              talentInsolite={host.talentInsolite}
              passions={host.passions}
              titreBiographique={host.titreBiographique}
              langues={host.langues}
              city={host.city}
            />
          </Grid>
        </Grid>

        <div style={{ marginTop: "50px" }}></div>

        {/* Experiences Section */}
        <h3>Mes expériences</h3>
        <Grid container spacing={0}>
          {/* First Experience */}
          

          {/*
            <Grid item xs={12} md={6}>
            <Food
              image={photos[0]?.id}
              titre={titre}
              typeCuisine={typeCuisine}
              prixParInvite={prixParInvite}
              rating={rating}
              description={description}
            />
          </Grid>*/
          }
          {
            experience.map((e, index) => ( 
              e &&
              <Grid item onClick={() => navigate('/annonce', { state: e })}
              
              sx={{
                '&:hover': {
                  transform: 'scale(1.05)',
                  transition: 'transform 0.3s ease-in-out',
                  cursor: 'pointer',
                },
              }}
              >
                <Food
                  image={e.photos[0]?.id}
                  titre={e.titre}
                  typeCuisine={e.typeCuisine}
                  prixParInvite={e.prixParInvite}
                  rating={e.rating}
                  description={e.description}
                />
              </Grid>
            ))
          }
          
        </Grid>
      </Container>

      <Footer />
    </>
  );
}

import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import card1 from "../img/card1.png";
import card2 from "../img/card2.png";
import card3 from "../img/card3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Mousewheel } from "swiper/modules";
import { useMediaQuery } from "@mui/material";
import { createTheme } from "@mui/material/styles";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
export default function Experiences() {
  const cards = [
    { image: card1, alt: "Chez l'hôte" },
    { image: card2, alt: "Food Tour" },
    { image: card3, alt: "Cours de cuisine" },
  ];
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Vérifie si en mode mobile

  return (
    <div
      style={{
        marginBottom: "5px",
        marginTop: isMobile?"100px":"0px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          marginBottom: {
            fontFamily: "Poppins",
          },
          fontWeight: "500",
          marginLeft: { xs: "23px", sm: "auto", md: "30px", lg: "24px" },
        }}
      >
        Expériences
      </Typography>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        scrollbar={{
          hide: false,
          draggable: true,
        }}
        mousewheel={true}
        modules={[Scrollbar, Mousewheel]}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        style={{
          "--swiper-scrollbar-border-radius": "10px",
          "--swiper-scrollbar-top": "auto",
          "--swiper-scrollbar-bottom": "4px",
          "--swiper-scrollbar-left": "auto",
          "--swiper-scrollbar-right": "4px",
          "--swiper-scrollbar-sides-offset": "5%",
          "--swiper-scrollbar-bg-color": "white",
          "--swiper-scrollbar-drag-bg-color": "#888",
          "--swiper-scrollbar-size": "8px",
        }}
      >
        {cards.map((card, index) => (
          <SwiperSlide key={index}>
            <Card
              sx={{
                boxShadow: "none",
                padding: 0,
                margin: "0 auto",
                scale: "0.85",
              }}
            >
              <CardActionArea
                sx={{
                  padding: 0,
                  "&:hover": {
                    transform: "scale(1.05)",
                    transition: "transform 0.3s ease-in-out",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: "100%",
                    height: "auto",
                  }}
                  image={card.image}
                  alt={card.alt}
                />
              </CardActionArea>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
